/*! *****************************************************************************
*T: This file manages the state related to sports and updates it via Redux.
*H: PROGRAMMER    : Thamizharasan
*H: DATE-WRITTEN  :15/06/2024
*H: (C) COPYRIGHT 2024 GI TECH GAMING CO INDIA PRIVATE LIMITED .All rights reserved.

***************************************************************************** */




import { appConstants } from "../helpers/actionType";
let PastResult = [];
let csports = {};
let cRegion = {};
let ccountries = {};
let cMatch = null;
let cTournament = {};
let ccode = ""
let operators = [];
try {
    PastResult = JSON.parse(localStorage.getItem('region'));
    PastResult = PastResult ? PastResult : [];
    csports = JSON.parse(localStorage.getItem('csports'));
    csports = csports ? csports : {};
    cRegion = JSON.parse(localStorage.getItem('cregion'));
    cRegion = cRegion ? cRegion : {};
    ccountries = JSON.parse(localStorage.getItem('ccountries'));
    ccountries = ccountries ? ccountries : {};
    cMatch = JSON.parse(localStorage.getItem('cmatch'));
    cMatch = cMatch ? cMatch : null;  
    cTournament = JSON.parse(localStorage.getItem('cTour'));
    cTournament = cTournament ? cTournament : {}
    ccode = localStorage.getItem('ccountrycode');
    operators = JSON.parse(localStorage.getItem('operators'));
    operators = operators ? operators : [];
   

}
catch (ex) {

}
const initialState = {
    RegionList: PastResult, SportsList: [], CurrentSports: csports, CurrentRegion: cRegion,
    CurrentCalenderDate: new Date(), allMatchs: [], CurrentMatch: cMatch,
    CountryList:[],CurrentCountries: ccountries, AllMatchesbyID: [], Tournaments: [], TournamentMatches: [], TourMatch: {},
    CurrentMatchOdds: [], CountryTournaments: [],AvailSubMarkets:[], CurrentTournament: cTournament, ccountrycode: ccode, operators: operators,liveMaches: []
}
export const sportsReducer = (state = initialState, action) => {
    switch (action.type) {
        case appConstants.GET_REGIONS:
            localStorage.setItem('region', JSON.stringify(action._region))
            return {
                ...state,
                // CurrentRegion: Object.keys(state.CurrentRegion).length == 0 && action._region?.continents?.length > 0 ? action._region.continents[0] : state.CurrentRegion,
                RegionList: action._region
            };
        case appConstants.GET_SPORTS:
            return {
                ...state,
                CurrentSports: Object.keys(state.CurrentSports).length === 0 && action._sports?.sports?.length > 0 ? action._sports.sports[0] : state.CurrentSports,
                SportsList: action._sports
            };
        case appConstants.GET_COUNTRIES:

            return {
                ...state,
                CountryList: action._countries
            }
        case appConstants.SEARCH_SPORTS:
            return {
                ...state,
                SearchSportsList: action._ssports
            };
        case appConstants.SET_SELECTED_SPORTS:
            localStorage.setItem('csports', JSON.stringify(action.obj));
            return {
                ...state,
                CurrentSports: action.obj
            };
        case appConstants.SET_SELECTED_REGION:
            localStorage.setItem('cregion', JSON.stringify(action.obj));
            return {
                ...state,
                CurrentRegion: action.obj
            };
        case appConstants.SET_SELECTED_COUNTRIES:
                localStorage.setItem('ccountries', JSON.stringify(action.obj));
                return {
                    ...state,
                    CurrentCountries: action.obj
                };
        case appConstants.SET_CALENDER_DATE:
            return {
                ...state,
                CurrentCalenderDate: action.obj
            };
        case appConstants.SET_MATCH:
            return {
                ...state,
                allMatchs: action._match
            };
        case appConstants.SET_CALENDER_MATCH:
            return {
                ...state,
                TournamentMatches: action.obj
            };
        case appConstants.SET_SELECTED_MATCH:
            localStorage.setItem('cmatch', JSON.stringify(action.obj));
            return {
                ...state,
                CurrentMatch: action.obj
            };
            case appConstants.SET_SELECTED_TOUR: 
            localStorage.setItem('cTour', JSON.stringify(action.obj))           
            return {
                ...state,
                CurrentTournament: action.obj
            };
            
            case appConstants.SET_MATCH_ODDS:            
            return {
                ...state,
                CurrentMatchOdds: action.obj
            }; 
            case appConstants.GET_MATCHESLIST_BY_ID:
                return {
                    ...state,
                    AllMatchesbyID: action._matcheslistbyid
                }; 
            case appConstants.GET_TEAM_BY_ID:
                return {
                    ...state,
                    // AllMachesforTeam: action._matchesteam
                    TournamentMatches: action._teamlistbyid
                };
        case appConstants.GET_MATCHES_FOR_TOURNAMENT:
            return {
                ...state,
                TournamentMatches: action._match
            };
        case appConstants.SET_SELECTED_TOURNAMENT_MATCH:         
            return {
                ...state,
                TourMatch: action.obj
            };
        case appConstants.CLEAR_SPORTITEM:
            return{
                ...state,
                SearchSportsList:[]
            }
            case appConstants.GET_TOURNAMENTS_FOR_COUNTRY_AND_SPORTS:
                return {
                    ...state,
                    Tournaments: action._tournaments
                };
            case appConstants.GET_COUNTRY_TOURNAMENTS:
                return {
                    ...state,
                    CountryTournaments: action._Countrytournaments
                };
            case appConstants.AVAIL_SUB_MARKET:
                 return {
                    ...state,
                    AvailSubMarkets: action.mobj
               };
            case appConstants.GET_MATCH_BYID:
                return {
                   ...state,
                   TournamentMatches: action._matchesteam
              };
            case appConstants.ALL_OPRATORS:
                localStorage.setItem('operators', JSON.stringify(action._aoperators))
                return {
                    ...state,
                    operators: action._aoperators
                };
            case appConstants.ALL_LIVE_MATCHES:
                return {
                   ...state,
                   liveMaches: action._livematch || []
                  };
        default:
         return { ...state }
    }
}