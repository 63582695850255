/*! *****************************************************************************
*T: This file manages and displays the league table.
*H: PROGRAMMER    : Thamizharasan
*H: DATE-WRITTEN  : 05/07/2024
*H: (C) COPYRIGHT 2024 GI TECH GAMING CO INDIA PRIVATE LIMITED .All rights reserved.

***************************************************************************** */




import React, { Component, useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { sportsActions } from '../../action/SportsAction';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

class LeagueTable extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  componentDidUpdate() {
  }

  render() {
    const { t, flagHeading3 } = this.props;
    const truncateText = (text, maxLength) => {
      if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
      }
      return text;
    };
    return (
      <>
        {flagHeading3 && <h2 className="heading-2">{t("lblLeagueTable")}</h2>}
        { flagHeading3 && <div className="heading-4 bg1">{t("lblLeagueName")}</div>}
        {this.props.CurrentMatchOdds?.tournament_table?.[0]?.subtournament_table && (
          <div className="leagueTable">
            <table className="tble">
              <tbody>
                <tr>
                  <th></th>
                  {this.props.CurrentSports?.table_labels.split(",")?.map((x, i) => (
                    <th key={i}>{x}</th>
                  ))}
                  <th>From</th>
                </tr>
               
                {this.props.CurrentMatchOdds && this.props.CurrentMatchOdds.tournament_table && this.props.CurrentMatchOdds.tournament_table[0].subtournament_table && 
                this.props.CurrentMatchOdds.tournament_table[0].subtournament_table.map((tms, i) => (
                  <tr className={tms.team_id == this.props.CurrentMatchOdds.at_id || tms.team_id == this.props.CurrentMatchOdds.ht_id ? "lTblActive" : ""}>
                    <td>
                      <div className="teamNameline greenbg"></div>
                      <div className="teamPg"><img src={process.env.REACT_APP_CDN_URL + tms.team_flag_url} alt='' /></div>
                      {/* <div className="ltTeamName">{tms.team_name}</div> */}
                      <div className="ltTeamName">{truncateText(tms.team_name, 10)}</div>
                    </td>
                    <td>{tms.total_fields?.team_matches_total}</td>
                    <td>{tms.total_fields?.team_win_total}</td>
                    <td>{tms.total_fields?.team_draw_total}</td>
                    <td>{tms.total_fields?.team_loss_total}</td>
                    <td>{tms.total_fields?.team_goals_total.split(":")[0]}</td>
                    <td>{tms.total_fields?.team_goals_total.split(":")[1]}</td>
                    <td>{tms.team_points}</td>

                    <td >
                      <div className="pointsCont">
                        <div className="clrtxt1">W</div>
                        <div>D</div>
                        <div className="clrtxt3">W</div>
                        <div>D</div>
                        <div className="clrtxt2">W</div>
                      </div>
                    </td>
                  </tr>

                ))}
              </tbody>
            </table>
          </div>)}
      </>
    )
  }
}

function mapStateToProps(state) {
  
  const { allMatchs, CurrentMatch, CurrentSports, CurrentMatchOdds } = state.sportsReducer;
  return {  allMatchs, CurrentMatch, CurrentSports, CurrentMatchOdds };
}
const mapActionToProps = {
  SetSelectedMatch: sportsActions.SetSelectedMatch,

}
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(LeagueTable))