/*! *****************************************************************************
*T: For single line progress baar in statics
*H: PROGRAMMER    : Thamizharasan
*H: DATE-WRITTEN  : 25/07/2024
*H: (C) COPYRIGHT 2024 GI TECH GAMING CO INDIA PRIVATE LIMITED .All rights reserved.

***************************************************************************** */




import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

class SingleLineProgressBar extends Component {
  render() {
    const { progress, className } = this.props;
    return (
        <>
        {this.props.CurrentMatchOdds?.tournament_start_date && this.props.CurrentMatchOdds?.Tournament_end_date && (
      <div className='sinLinePbar bg1'>
      <span>{new Date(this.props.CurrentMatchOdds?.tournament_start_date).toLocaleDateString("default", { month: "short", day: "numeric" })}</span>
       <span style={{ float: "right" }}>{new Date(this.props.CurrentMatchOdds?.Tournament_end_date ).toLocaleDateString("defaut", {month: "short", day: "numeric"})}</span>
        <div className={`single-line-progress-bar-container ${className}`}>
          <div className="single-line-progress-bar" style={{ width: `${progress}%` }}></div>
        </div>
      </div>
        )
        
      }
      </>
    );
  }
}

SingleLineProgressBar.propTypes = {
  progress: PropTypes.number.isRequired,
  className: PropTypes.string,
};

SingleLineProgressBar.defaultProps = {
  className: '',
};

function mapStateToProps(state){
  const {CurrentMatchOdds} = state.sportsReducer;
  return { CurrentMatchOdds}
}
const mapActionToProps = {

}
export default connect(mapStateToProps, mapActionToProps) (withTranslation()(SingleLineProgressBar)) ;
