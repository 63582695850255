/*! *****************************************************************************
*T: This file handles the footer section and its content rendering.
*H: PROGRAMMER    : Thamizharasan
*H: DATE-WRITTEN  : 20/06/2024
*H: (C) COPYRIGHT 2024 GI TECH GAMING CO INDIA PRIVATE LIMITED .All rights reserved.

***************************************************************************** */



import React, {useState} from 'react'
import {Link} from 'react-router-dom'

function Footer() {
    const [icon, setIcon] = useState(false)
    const closeSideDrawer = () =>{setIcon(false)}
    return (
           
        <footer className='bg1'>
            <div className='foot25'>
                <div className='footLogo'>
                    <img src="/assets/images/logo.png" />
                </div>
                <p className="footRgtsTxt">Copyright © 2024 <span>Oddsium EN | Powered by Oddsium EN</span></p> 
            </div>
            <div className='foot50'>
                <ul className="footGameLogos bg1">
                    <li><Link to ="#"><img src="/assets/images/sports/betsafe.png" /></Link></li>
                    <li><Link to ="#"><img src="/assets/images/sports/betfair.png" /></Link></li>
                    <li><Link to ="#"><img src="/assets/images/sports/unibet.png" /></Link></li>
                    <li><Link to ="#"><img src="/assets/images/sports/888sport.png" /></Link></li>
                    <li><Link to ="#"><img src="/assets/images/sports/william-hill.png" /></Link></li>
                    <li><Link to ="#"><img src="/assets/images/sports/mr-green.png" /></Link></li>
                    <li><Link to ="#"><img src="/assets/images/sports/nordicbet.png" /></Link></li>
                </ul>
            </div>
            <div className='foot25'>
                <Link to ='/responsible-gaming' className='footResGaming'><img src="/Assets/images/icons/resgame.png" alt="Logo" />Responsible  Gaming</Link>
            </div>
        </footer>  
    )
  }
  
  export default Footer