import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Tabslider2 = (props) => {
  const [activeIndex, setActiveIndex] = useState(1);
  const [slidesToShow, setSlidesToShow] = useState(1);
  const [isCenterMode, setIsCenterMode] = useState(false);

  const setActiveIndex1 = (element) => {
    setActiveIndex(element.id);
    props.selectSubMarket(element.id);
  }
  
  const subMarketlsit = [
    { id: 1, name: "Full Time Result" },
    { id: 2, name: "Double Chance" },
    { id: 4, name: "Both Teams Scores" },
    { id: 5, name: "Half Time Result" },
    { id: 38, name: "Match Winner" },
    { id: 41, name: "Match Handicap -1.5, 1.5" },
    { id: 43, name: "Match Spread -1.5, 1.5" },
    { id: 44, name: "Map 1 Spread -7.5, 7.5" },
    { id: 45, name: "Map 2 Spread -7.5, 7.5" },
    { id: 46, name: "Map 3 Spread -6.5, 6.5" },
    { id: 232, name: "1st Half Winner" },
    { id: 250, name: "Draw No Bet" },
    { id: 251, name: "Total Goals (Odd/Even)" },
    { id: 252, name: "To Go The Distance" },
    { id: 264, name: "Total Points (Odd/Even)" },
    { id: 265, name: "To Go Into Overtime" },
    { id: 266, name: "Match Winner (Excl. OT)" },
    { id: 980, name: "Total Points" },
    { id: 982, name: "Point Spread" },
    { id: 983, name: "Set - Winner" },
    { id: 984, name: "Total Games" },
    { id: 985, name: "Set 1 - Total Games" },
    { id: 986, name: "Set 2 - Total Games" },
    { id: 987, name: "Set 3 - Total Games" },
    { id: 988, name: "Total Sets" },
    { id: 989, name: "Innings - Winner" },
    { id: 990, name: "Over Under (Excl. OT)" },
    { id: 991, name: "Total Rounds" },
    { id: 992, name: "Total Runs" },
    { id: 993, name: "Map - Total Rounds (Odd/Even)" },
    { id: 994, name: "Map - Winner" },
    { id: 995, name: "Map - Total Rounds" },
    { id: 996, name: "Total Maps" },
    { id: 997, name: "Total Points" },
    { id: 998, name: "Asian Handicap" },
    { id: 999, name: "Over Under" }
  ].filter(sm => props.availableMarkets && props.availableMarkets.some(am => sm.id == am.submarket_id));

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 800) {
        setSlidesToShow(Math.min(subMarketlsit.length, 3));
        setIsCenterMode(subMarketlsit.length > 3);
      } else {
        setSlidesToShow(Math.min(subMarketlsit.length, 5));
        setIsCenterMode(subMarketlsit.length > 5);
      }
    };

    // Initial check
    handleResize();

    // Add event listener
    window.addEventListener('resize', handleResize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [subMarketlsit.length]);

  const settings = {
    dots: true,
    infinite: subMarketlsit.length > slidesToShow,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    initialSlide: 0,
    centerMode: isCenterMode, // Enable center mode for partial visibility of next/prev slides
    centerPadding: isCenterMode ? '30px' : '0px', // Adjust padding to show part of the next and previous slides
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    beforeChange: (current, next) => setActiveIndex(next),
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: Math.min(subMarketlsit.length, 3),
          centerMode: subMarketlsit.length > 3,
          centerPadding: subMarketlsit.length > 3 ? '15px' : '0px',
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: Math.min(subMarketlsit.length, 6),
          centerMode: subMarketlsit.length > 6,
          centerPadding: subMarketlsit.length > 6 ? '30px' : '0px',
        }
      }
    ]
  };

  return (
    <div className="third-slider-container">
      <Slider {...settings}>
      {subMarketlsit && subMarketlsit.map((element, inx) =>
          <div onClick={() => { setActiveIndex1(element) }} className={element.id == activeIndex ? 'slick-slide active' : 'slick-slide'}>
            <a>{element.name}</a>
          </div>
        )}
      </Slider>
    </div>
  );
};

const CustomPrevArrow = (props) => {
  const { className, onClick } = props;
  return <div className={className} onClick={onClick}><img src="/Assets/images/icons/left-angle2.png" alt="Previous" /></div>;
};

const CustomNextArrow = (props) => {
  const { className, onClick } = props;
  return <div className={className} onClick={onClick}><img src="/Assets/images/icons/right-angle2.png" alt="Next" /></div>;
};

export default Tabslider2;
