/*! *****************************************************************************
*T: This file handles and displays region-related data
*H: PROGRAMMER    : Thamizharasan
*H: DATE-WRITTEN  : 27/07/2024
*H: (C) COPYRIGHT 2024 GI TECH GAMING CO INDIA PRIVATE LIMITED .All rights reserved.

***************************************************************************** */




import React, { Component, useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { sportsActions } from '../../action/SportsAction';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
class Regions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedRegion: null,
            isRegionFetched: 0,
            selectedCountry: null
        };
    }
    static getDerivedStateFromProps(props, state) {
        return {
            CurrentSports: props.CurrentSports,
            CurrentRegion: props.CurrentRegion,
            RegionList: props.RegionList
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.state.CurrentSports && this.state.CurrentSports.id && (this.state.isRegionFetched == 0 || this.state.CurrentSports.id !== prevState.CurrentSports.id)) {
            this.props.GetRegions(this.state.CurrentSports.id, 0, 0, 0, 0);
            this.state.isRegionFetched = 1;
        }
        // if (this.props.RegionList && this.props.RegionList.continents && this.props.CurrentRegion.id != this.state.CurrentRegion.id) {
        //     this.props.SetSelectedRegion(this.props.RegionList.continents[0]);
        // }
        if (this.state.selectedRegion == null && this.props.CurrentRegion && this.props.CurrentRegion.id) {
            this.showCountries(this.props.CurrentRegion);
        }

        // if (this.props.CurrentSports && prevProps.CurrentSports && this.props.CurrentSports.id !== prevProps.CurrentSports.id) {
        //     this.props.GetRegions(this.state.CurrentSports.id, 0, 0, 0, 0);
        // } 
 
        if (!this.props.CurrentCountries.id && this.props.CountryList?.countries && this.props.CountryList.countries.length > 0) {
            // this.showCountryTornaments(this.props.CountryList.countries[0]);
        this.props.SetSelectedCountry(this.props.CountryList.countries[0])
          }
    }

    showCountries = (element) => {
        let data = { continent: element.id, sport: this.state.CurrentSports.id };
        this.props.GetCountry(data);
        if (this.state.selectedRegion && this.state.selectedRegion.id === element.id) {
            // this.setState({ selectedRegion: null });
        } else {
            this.setState({ selectedRegion: element });
            this.props.SetSelectedRegion(element)
        }
    }

    showCountryTornaments = (country) => {
        this.props.SetSelectedCountry(country)
        let data = { country: country.id, sport: this.state.CurrentSports.id };
        this.props.GetTournaments(data)
        // this.props.GetCountryTournaments(data);
        // if (this.state.selectedCountry && this.state.selectedCountry.id === country.id) {
        //     this.setState({ selectedCountry: null });
        // } else {
        //     this.setState({selectedCountry: country})
        // }
    }
    getTournamentsByid = (trnmt)=>{
        this.props.SetSelectedTour(trnmt)
        let data = { Tournament: trnmt.id, Matchid: this.props.CurrentSports.id , countryCode: this.props.ccountrycode}
        this.props.GetMatchesforTournament(data, 0, 0, 0, 0)
    }
   
    render() {
        const { t } = this.props;
        return (
            <>
                <span className='lbl-1'>{t("lblregions")}</span>
                <div className='quickLink quickLinkCuntry'>
                    <ul>
                        {
                            this.props.RegionList && this.props.RegionList.continents &&
                            this.props.RegionList.continents.map((element, inx) =>
                                <li  >
                                    <a onClick={() => this.showCountries(element)} className={`bg1 ${this.props.CurrentRegion && this.props.CurrentRegion.id === element.id ? 'menuActive' : ''}`}>
                                        <div>
                                            <img src={process.env.REACT_APP_CDN_URL + element.flag_url} /></div><span>{element.name}</span>
                                        {/* <small>{this.props.CountryList?.countries?.length > 0 && <span>{this.props.CurrentRegion.id === element.id && this.props.CountryList?.countries?.length}</span>}</small> */}
                                    </a>
                                    {this.state.selectedRegion && this.state.selectedRegion.id === element.id && this.props.CountryList?.countries?.length > 0 && (
                                        <ul>
                                            {this.props.CountryList?.countries?.map((countries, index) => (
                                                <li key={index} >
                                                    <a className={`bg1 ${this.props.CurrentCountries && this.props.CurrentCountries.id == countries.id ? "menuActive" : ""}`} onClick={() => this.showCountryTornaments(countries)}>
                                                       <div> <img src={process.env.REACT_APP_CDN_URL + countries.flag_url} alt="" /></div>
                                                        <span>{countries.name}</span> </a>
                                                    {/* Tour list */}
                                         {/* {this.state.selectedCountry && this.state.selectedCountry.id == countries.id && this.props.CountryTournaments?.tournaments?.length > 0 && (
                                                <ul>
                                                     {this.props.CountryTournaments?.tournaments?.map((trnmt, idx) => (
                                                       <li key={idx} onClick={()=>this.getTournamentsByid(trnmt)}>
                                                             <a className={`bg1 ${this.props.CurrentTournament.name == trnmt.name ? "menuActive" : ""}`} >
                                                                <img src={process.env.REACT_APP_CDN_URL + trnmt.flag_url} alt={trnmt.name} />
                                                                <span>{trnmt.name}</span></a>
                                                      </li>
                                                 ))}
                                              </ul>
                                             )} */}
                                                    {/* Tour list */}
                                     </li>
                                     ))}
                                 </ul>
                                 )}
                             </li>
                            )
                        }
                        {/* <li><Link to="#"><img src="/Assets/images/country/america.png" /><span>America</span></Link></li>
                  
                    <li><Link to="#"><img src="/Assets/images/country/international.png" /><span>International</span></Link></li> */}

                    </ul>
                </div>
            </>
        )
    }
}

function mapStateToProps(state) {
  
    const { RegionList, CurrentSports, CurrentRegion, CountryList, CountryTournaments, CurrentTournament, CurrentCountries, ccountrycode } = state.sportsReducer;
    return { RegionList,  CurrentSports, CurrentRegion, CountryList, CountryTournaments, CurrentTournament, CurrentCountries, ccountrycode };
}
const mapActionToProps = {
    GetRegions: sportsActions.GetRegions,
    SetSelectedRegion: sportsActions.SetSelectedRegion,
    GetCountry: sportsActions.GetCountry,
    GetCountryTournaments: sportsActions.GetCountryTournaments,
    GetMatchesforTournament: sportsActions.GetMatchesforTournament,
    SetSelectedTour: sportsActions.SetSelectedTour,
    SetSelectedCountry: sportsActions.SetSelectedCountry,
    GetTournaments: sportsActions.GetTournaments
}
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Regions))