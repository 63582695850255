/*! *****************************************************************************
*T: this file handle and shows left pannel data
*H: PROGRAMMER    : Thamizharasan
*H: DATE-WRITTEN  : 05/07/2024
*H: (C) COPYRIGHT 2024 GI TECH GAMING CO INDIA PRIVATE LIMITED .All rights reserved.

***************************************************************************** */





import React, { Component } from 'react';
import { connect } from 'react-redux';
import { alertActions } from '../../action/AlertAction';
import { withTranslation } from 'react-i18next';
import Regions from './Regions';
import Sports from './Sports';
import Search from '../ToolKit/Search'

class Home extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount()
    {
      //  window.tets();
    }
    render() {
        const {t} =this.props;
        return (
            <>
                <Search />
                <span className='lbl-1'>{t("lblfilters")}</span>
                <Sports />
                <Regions />
                {/* <Country/>              */}
                {/* {/* <Calendar /> */}

                {/* <div className='calendarGame bg1'>
                    <ul>
                        <li><Link to="#"><img src="./assets/images/sports/allsvenskan.png" /><span>Allsvenskan</span></Link></li>
                        <li><Link to="#"><img src="./assets/images/sports/swedish.png" /><span>Swedish Cup</span></Link></li>
                        <li><Link to="#"><img src="./assets/images/sports/champions.png" /><span>Champions League</span></Link></li>
                        <li><Link to="#"><img src="./assets/images/sports/europa.png" /><span>Europa League</span></Link></li>
                    </ul>
                </div> */}
                {/* 
                {/* <LiveGames /> */}
                {/* <GamesCalendar /> */}
            </>
        );
    }
}


function mapStateToProps(state) {
   
    const { serverTime } = state.commonReducer;
    return {  };
}
const mapActionToProps = {
    alertError: alertActions.error,
    alertsucc: alertActions.success,
    clearAlerts: alertActions.clear,

}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Home))