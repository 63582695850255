/*! *****************************************************************************
*T: This file displays the complete list of all matches.
*H: PROGRAMMER    : Thamizharasan
*H: DATE-WRITTEN  : 15/06/2024
*H: (C) COPYRIGHT 2024 GI TECH GAMING CO INDIA PRIVATE LIMITED .All rights reserved.

***************************************************************************** */





import React, { Component, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { sportsActions } from '../../action/SportsAction';
import Tabslider3 from '../ToolKit/Tabslider3';
import Tabslider2 from '../ToolKit/Tabslider2';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import GamesStatics from './GamesStatics';
import MatchEvents from './MatchEvents';
import LeagueTable from './LeagueTable';
import LiveGames from './LiveGames';
class MatchList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      subMarketlsit: [
        { id: 1, name: "Full Time Result" },
        { id: 2, name: "Double Chance" },
        { id: 4, name: "Both Teams Scores" },
        { id: 5, name: "Half Time Result" },
        { id: 38, name: "Match Winner" },
        { id: 41, name: "Match Handicap -1.5, 1.5" },
        { id: 43, name: "Match Spread -1.5, 1.5" },
        { id: 44, name: "Map 1 Spread -7.5, 7.5" },
        { id: 45, name: "Map 2 Spread -7.5, 7.5" },
        { id: 46, name: "Map 3 Spread -6.5, 6.5" },
        { id: 232, name: "1st Half Winner" },
        { id: 250, name: "Draw No Bet" },
        { id: 251, name: "Total Goals (Odd/Even)" },
        { id: 252, name: "To Go The Distance" },
        { id: 264, name: "Total Points (Odd/Even)" },
        { id: 265, name: "To Go Into Overtime" },
        { id: 266, name: "Match Winner (Excl. OT)" },
        { id: 980, name: "Total Points" },
        { id: 982, name: "Point Spread" },
        { id: 983, name: "Set - Winner" },
        { id: 984, name: "Total Games" },
        { id: 985, name: "Set 1 - Total Games" },
        { id: 986, name: "Set 2 - Total Games" },
        { id: 987, name: "Set 3 - Total Games" },
        { id: 988, name: "Total Sets" },
        { id: 989, name: "Innings - Winner" },
        { id: 990, name: "Over Under (Excl. OT)" },
        { id: 991, name: "Total Rounds" },
        { id: 992, name: "Total Runs" },
        { id: 993, name: "Map - Total Rounds (Odd/Even)" },
        { id: 994, name: "Map - Winner" },
        { id: 995, name: "Map - Total Rounds" },
        { id: 996, name: "Total Maps" },
        { id: 997, name: "Total Points" },
        { id: 998, name: "Asian Handicap" },
        { id: 999, name: "Over Under" }
      ],
      selectedDate: new Date(),
      selectedShowdate: "",
      c_sub_marketid: null,
      isFetchlist: 0,
      tabActive: 0,
    }
    this.matchRefs = {}
    let tmr = localStorage.getItem("ntimer");
    this.NormalTimer = tmr ? parseInt(tmr) : 60000;
    let tmr1 = localStorage.getItem("ltimer");
    this.liveTimer = tmr1 ? parseInt(tmr1) : 30000
  }
  handleDateChange = (date) => {
    this.setState({ selectedDate: date });
    let dd = date.getFullYear() + '-' + (("0" + (date.getMonth() + 1)).slice(-2)) + "-" + ("0" + date.getDate()).slice(-2);
    let dd1 = date.toDateString();
    this.setState({ selectedShowdate: dd1 })
    let data = { sdate: dd, sport: this.props.CurrentSports.id, countryCode: this.props.ccountrycode };
    this.props.GetCalenderMatch(data, 0, 0, 0, 0);
  };

  componentDidMount() {
    window.mobmenu();
    if(this.props.CurrentCountries?.id){
      let data = { countryCode: this.props.CurrentCountries?.id }
    this.props.GetAvlblOperators(data)
    }
    
  }
  openMatchList = (item, flag) => {
    if (flag !== 1 && this.props.CurrentMatch && this.props.CurrentMatch.id === item.id) {
      this.props.SetSelectedMatch({ id: 0 });
    }
    else {
      this.props.SetSelectedMatch(item)
      this.selectSubMarket(this.props.CurrentSports.default_submarket, item.id)
    }
  }
  static getDerivedStateFromProps(props, state) {
    return {
      CurrentMatch: props.CurrentMatch,
      CurrentMatchOdds: props.CurrentMatchOdds,
      // TournamentMatches: state.TournamentMatches?.length != props.TournamentMatches?.length ? props.TournamentMatches : state.TournamentMatches,
      TournamentMatches: props.TournamentMatches,
      liveMaches: props.liveMaches
    };
  };
  selectSubMarket = (id, machID) => {
    this.state.c_sub_marketid = id;
    if (this.state.CurrentMatch) {
      machID = machID ? machID : this.state.CurrentMatch.id;
      let data = { matchid: machID, sub_marketid: this.state.c_sub_marketid, countrycode: this.props.ccountrycode, oddstype: "1" };
      this.props.GetOddsByMatch(data);
    }
  }
  componentDidUpdate(prevProps) {
    if (this.state.isFetchlist == 0 && this.props.CurrentSports && this.props.CurrentSports.id) {
      this.handleDateChange(this.state.selectedDate);
      this.state.isFetchlist = 1;
      this.props.SetSelectedMatch({ id: 0 })
      this.startAlltourinterval();
    }
    this.mergeLivegamesToAllgames();
    // if(this.props.CurrentMatch && this.props.CurrentMatch.id != prevProps.CurrentMatch.id){
    //   this.openMatchList(this.props.CurrentMatch)
    // }
    if (prevProps.CurrentMatch?.id !== this.props.CurrentMatch?.id) {
      this.scrollToMatch(this.props.CurrentMatch);
      this.openMatchList(this.props.CurrentMatch, 1)
    }

    if (this.props.CurrentCountries?.id !== prevProps.CurrentCountries?.id) {
      let data = { countryCode: this.props.CurrentCountries?.id };
      this.props.GetAvlblOperators(data);
    }
    if (this.props.CurrentSports?.id !== prevProps.CurrentSports?.id) {
      clearInterval(this.oddsInterval);
      this.handleDateChange(this.state.selectedDate);
    }

    setTimeout(() => {
      window.aScrollDynamic();
    }, 2000);

    if (this.props.CurrentMatchOdds?.operator_odds?.length !== prevProps.CurrentMatchOdds?.operator_odds?.length) {
      this.startOddsInterval();
    }

  }
  showRightpnl = (index) => {
    this.setState({ tabActive: index })
  }

  scrollToMatch = (cmatch) => {
    if (cmatch?.id && this.matchRefs[cmatch.id]) {
      this.matchRefs[cmatch.id].scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  showOperator = (ele) => {
    const matchingOperator = this.props.operators?.operator?.find(ts => ts.id === ele.operator_id);
    if (matchingOperator?.affiliate_url) {
      window.open(matchingOperator.affiliate_url, '_blank');
    }
  }

  // refresh every 30 sec to get updated odds score 
  startOddsInterval = () => {
    clearInterval(this.oddsInterval);
    try {
      if (this.state.CurrentMatch && this.state.CurrentMatch.id && this.props.CurrentMatchOdds?.operator_odds?.length > 0) {
        const data = { matchid: this.state.CurrentMatch.id, sub_marketid: this.state.c_sub_marketid, countrycode: this.props.ccountrycode, oddstype: "1" };
        this.oddsInterval = setInterval(() => this.props.GetOddsByMatch(data), this.props.CurrentMatchOdds?.live_status != "" ? this.liveTimer : this.NormalTimer);
      }

    } catch (error) {
      console.error('Error in startOddsInterval', error);
    }
  }
  // to refresh all matches aftere a minute
  startAlltourinterval = () => {
    clearInterval(this.alltourmatchIntrvl);
    try {
      if (this.props.CurrentSports && this.props.CurrentSports.id && this.state.selectedDate) {
        this.alltourmatchIntrvl = setInterval(() => this.handleDateChange(this.state.selectedDate), this.NormalTimer);
      }
    } catch (error) {
      console.error('Error in alltourmatchIntrvl', error);
    }
  }
  componentWillUnmount() {
    clearInterval(this.oddsInterval);
  }

  mergeLivegamesToAllgames = () => {
    if (this.state.liveMaches?.length > 0 && this.state.TournamentMatches?.length > 0) {
      this.state.liveMaches?.forEach(lmatch => {
        let matchIndex = this.state.TournamentMatches.findIndex(tmatch => tmatch.id === lmatch.id);
        if (matchIndex != -1) {
          this.state.TournamentMatches[matchIndex] = lmatch;
        //   if(this.state.TournamentMatches[matchIndex].id== lmatch.id)
        //   {
        //     this.state.TournamentMatches[matchIndex].at_name="thamil";
        //   }
        }
      });

    }
  }


  render() {
    const { t } = this.props;

    // Make Tournament groups
    let matchGroup = {};
    let data = this.state.TournamentMatches;
    for (let a = 0; a < data?.length; a++) {
      let match = data[a];
      let TournamentName = match.tournament_name;
      if (!matchGroup[TournamentName]) {
        matchGroup[TournamentName] = [];
      }
      matchGroup[TournamentName].push(match);
    }
    let liveMatches = [];
    let nonLiveMatches = [];

    Object.entries(matchGroup)?.forEach(([tournamentName, matches]) => {
      matches.sort((a, b) => (a.live_status !== "" ? -1 : 1));
      if (matches.some(match => match.live_status !== "")) {
        liveMatches.push([tournamentName, matches]);
      } else {
        nonLiveMatches.push([tournamentName, matches]);
      }
    });
    let fmatchData = [...liveMatches, ...nonLiveMatches]

    // Calculate max values for each column
    const maxValues = [];
    const operatorOdds = this.props.CurrentMatchOdds?.operator_odds || [];

    for (let i = 0; i < operatorOdds.length; i++) {
      let row = operatorOdds[i].odds;
      for (let j = 0; j < row.length; j++) {
        let eodds = row[j];
        if (maxValues[j] === undefined || eodds.odds_value > maxValues[j]) {
          maxValues[j] = eodds.odds_value;
        }
      }
    }

    return (
      <div className='cent'>
        <Tabslider3 handleDateChange={this.handleDateChange} />
        {window.innerWidth < 1080 && <LiveGames />}
        {this.state.TournamentMatches && this.state.TournamentMatches.length > 0 ? <>
          <span className='lbl-1'>{this.state.TournamentMatches && `${this.state.TournamentMatches.length} ${t("lblByselectionGame")}`}</span>
          <div className='matchCentre scrollbar'>
            <ul className="matchCentreCont">
              {fmatchData.map(([tournamentName, matches]) => (
                <>
                  {matches.map((item, index) => (
                    <li key={item.id}
                      ref={el => (this.matchRefs[item.id] = el)} >
                      {index == 0 && <div className='teamNmeTime bg2'>
                        <img className='headingIcon1' src={process.env.REACT_APP_CDN_URL + matches[0].tournament_flag_url} alt="Tournament Flag" />
                        <img className='headingIcon2' src={process.env.REACT_APP_CDN_URL + this.props.CurrentSports?.flag_url} alt="Sport Icon" />
                        {tournamentName}, {matches[0].date?.split('T')[1]?.split(':')?.slice(0, 2)?.join(':')}
                      </div>}
                      <a onClick={() => { this.openMatchList(item) }}>
                        <div className='mtchCntrehomeAway bg1'>
                          <div className='mtchCntreTvIcon'>
                            {/* {item.has_stream && <span><img src="/assets/images/icons/tv.png" /></span>} */}
                          </div>
                          <div className='mtchCntrehACont'>
                            <div className='mtchCntreLft'>
                              <div className='myBethAteamLogo'><img src={process.env.REACT_APP_CDN_URL + item.ht_flag_url} /></div>
                              <div className='myBethAteamName'>{item.ht_name}</div>
                            </div>
                            <div className='mtchCntreCent'>
                              <span className='mtchCntreCenteTop'> {item.status != "Completed" && item.status != "Upcoming" && item.status} <span className={item.live_status !== "" ? 'liveSign' : ""}></span></span>
                              <span className='points'>{item.score?.ht_score && item.score?.at_score && `${item.score.ht_score} - ${item.score.at_score}`}</span>
                            </div>
                            <div className='mtchCntreRht'>
                              <div className='myBethAteamName'>{item.at_name}</div>
                              <div className='myBethAteamLogo'><img src={process.env.REACT_APP_CDN_URL + item.at_flag_url} /></div>
                            </div>
                          </div>
                          <div className='barSec'>
                            {this.props.CurrentMatchOdds?.progress_bar && this.props.CurrentMatch?.id == item?.id && item.live_status !== "" ?
                              <div className="bar"><span className="percent progressBar" >{this.props.CurrentMatchOdds?.progress_bar}</span></div>
                              :
                              <div><span></span></div>
                            }
                            {/* <div className="bar"><span className="percent progressBar" >47' 2H</span></div> */}
                          </div>
                          <div className='showAdds' >
                            {t("lblOdds")}
                            <span><img src={this.state.CurrentMatch && item.id === this.state.CurrentMatch.id && this.props.CurrentMatch?.id === this.state.CurrentMatchOdds?.id ? '/assets/images/icons/up-angle-w.png' : '/assets/images/icons/down-angle.png'} /></span>
                          </div>
                        </div>
                      </a>
                      {this.state.CurrentMatch && item.id === this.state.CurrentMatch.id && <>
                        <div className='matchCentreDnSec bg1'>
                          <div className='matchCentreDn'>
                            {this.state.CurrentMatchOdds && this.state.CurrentMatchOdds.operator_odds && this.props.CurrentMatch.id === this.state.CurrentMatchOdds.id &&
                              (
                                <>
                                  {/* <Tabslider2 selectSubMarket={this.selectSubMarket} c_sub_marketid={this.state.c_sub_marketid} availableMarkets={this.state.CurrentMatchOdds.available_submarkets} /> */}
                                  <Tabslider2 selectSubMarket={this.selectSubMarket} c_sub_marketid={this.state.c_sub_marketid} availableMarkets={this.props.AvailSubMarkets} />
                                  <ul className="matchSheLiCont matchCntrSheLiCont scrollbar">
                                    <li>
                                      <div className="SchduleHead vdrbg1">
                                        {this.state.CurrentMatchOdds.odds_name && this.state.CurrentMatchOdds.odds_name.map((ele, inx) =>
                                          <span key={inx} className={`HeadTxt ${inx === 1 ? "forRsltMargin" : ""}`}>{ele.name}</span>
                                        )}
                                      </div>
                                    </li>
                                    {this.state.CurrentMatchOdds.operator_odds && this.state.CurrentMatchOdds.operator_odds.map((ele, inx) => (
                                      <li key={inx} className="bg3">
                                        <div className="imageCont">
                                          <div className='mtchCntreTvIcon'>
                                            {/* {item.has_stream && <span><img src="/assets/images/icons/tv.png" alt="TV Icon" /></span>} */}
                                          </div>
                                          <span className="imgBet"><img src={process.env.REACT_APP_CDN_URL + ele.operator_flag_url} alt="Operator Flag" /></span>
                                          {/* <span className="giftIcon"><img src="/assets/images/icons/gift.png" /></span> */}
                                          {/* <span className="betVal">SEK 20.0</span> */}
                                        </div>
                                        <div className="result text-center">
                                          {ele.odds.map((eodds, inx1) => (
                                            <div key={inx1} className={`bettingContnr ${inx1 === 1 ? ' forRsltMargin' : ''}`}>
                                              {/* <a att="0" className={`${inx1 === 1 ? "bgw" : "ActiveBg"}`}> */}
                                              {/* <a att="0" className={Math.max(...ele.odds.map(o => o.odds_value)) == eodds.odds_value ? "ActiveBg" : "bgw"}> */}
                                              <a att="0" className={maxValues[inx1] === eodds.odds_value ? "ActiveBg" : "bgw"}>
                                                <div className="Betrht">
                                                  <span onClick={() => this.showOperator(ele)}>{eodds.odds_value_req}</span>
                                                </div>
                                              </a>
                                            </div>
                                          ))}
                                        </div>
                                      </li>
                                    ))}
                                  </ul>
                                </>
                              )

                            }
                          </div>
                        </div>
                        <div className="mobileTabs" style={{ marginTop: '10px' }}>
                          <ul className="mobileTabs">
                            <li><Link to="#" onClick={() => this.showRightpnl(0)} className={this.state.tabActive == 0 ? "active" : ""}>{t("lblgamesstatic")}</Link></li>
                            <li><Link to="#" onClick={() => this.showRightpnl(1)} className={this.state.tabActive == 1 ? "active" : ""}>{t("lblmatchevents")}</Link></li>
                            <li><Link to="#" onClick={() => this.showRightpnl(2)} className={this.state.tabActive == 2 ? "active" : ""}>{t("lblleaguetable")}</Link></li>
                          </ul>
                          <div style={{ marginTop: '30px' }}>
                            {this.state.tabActive == 0 && <GamesStatics />}
                          </div>
                          <div>
                            {this.state.tabActive == 1 && <MatchEvents />}
                          </div>
                          <div>
                            {this.state.tabActive == 2 && <LeagueTable />}
                          </div>
                        </div>
                      </>
                      }
                    </li>
                  ))}
                </>
              ))}
            </ul>
          </div>
        </>
          :
          (this.state.TournamentMatches && this.state.TournamentMatches.length === 0 && this.props.loading && <div>{t("lblLoading")}</div>)
        }
        {/* {this.props.TournamentMatches == null && <div>{0} {t("lblByselectionGame")}</div>} */}
      </div>
    )
  }
}
function mapStateToProps(state) {
  const { loading } = state.alertReducer;
  const { CurrentMatch, TournamentMatches, SearchSportsList, CurrentMatchOdds, CurrentCountries, CurrentSports, AvailSubMarkets, ccountrycode, operators, liveMaches } = state.sportsReducer;
  return {  CurrentMatch, TournamentMatches, SearchSportsList, CurrentMatchOdds, CurrentCountries, CurrentSports, loading, AvailSubMarkets, ccountrycode, operators, liveMaches };
}
const mapActionToProps = {
  GetOddsByMatch: sportsActions.GetOddsByMatch,
  SetSelectedMatch: sportsActions.SetSelectedMatch,
  GetCalenderMatch: sportsActions.GetCalenderMatch,
  GetAvlblOperators: sportsActions.GetAvlblOperators,
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(MatchList))
