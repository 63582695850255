/*! *****************************************************************************
*T: This file handles the API action functions and data processing
*H: PROGRAMMER    : Thamizharasan
*H: DATE-WRITTEN  : 12/06/2024
*H: (C) COPYRIGHT 2024 GI TECH GAMING CO INDIA PRIVATE LIMITED .All rights reserved.

***************************************************************************** */



import { appConstants } from '../helpers/actionType';
import { sportsService } from '../services/sportsService';
import { commonClass } from "../helpers/commonClass";

export const sportsActions = {
    GetRegions,
    GetSports,
    SearchSports,
    SetSelectedSports,
    SetSelectedRegion,
    SearchByDate,
    SetSelectedMatch,
    GetCalenderMatch,
    GetOddsByMatch,
    SetSelectedTour,
    GetCountry,
    SetSelectedCountry,
    GetMatchesById,
    GetTeamById,
    GetTournaments,
    GetMatchesforTournament,
    ClearSearchItem,
    GetCountryTournaments,
    GetLivegamesbyid,
    GetAvlblOperators
}

function GetSports(Ccode, mobileNo, langID, sessionKey) {
    try {
        return dispatch => {
            dispatch({ type: appConstants.LOADING });
            sportsService.GetSports(Ccode, mobileNo, langID, sessionKey)
                .then(
                    data => {
                        let _sports = data;
                        // let obj = data.sports[0];
                        dispatch({ type: appConstants.LOADED });
                        dispatch({ type: appConstants.GET_SPORTS, _sports });
                        // if (_sports && _sports.sports && _sports.sports.length > 0) {
                        //     let obj = _sports.sports[0];
                        //     dispatch({ type: appConstants.SET_SELECTED_SPORTS, obj });
                        // }
                    },
                    error => {
                        dispatch({ type: appConstants.LOADED });
                        let _sports = [];
                        dispatch({ type: appConstants.GET_SPORTS, _sports });
                    }
                );
        }
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
};
function GetRegions(data, Ccode, mobileNo, langID, sessionKey) {
    try {
        return dispatch => {
            dispatch({ type: appConstants.LOADING });
            sportsService.GetRegions(data, Ccode, mobileNo, langID, sessionKey)
                .then(
                    data => {
                        let _region = data;
                        dispatch({ type: appConstants.LOADED });
                        dispatch({ type: appConstants.GET_REGIONS, _region });
                        // if (_region && _region.length > 0) {
                        //     let obj = _region[0];
                        //     dispatch({ type: appConstants.SET_SELECTED_REGION, obj });
                        // }
                    },
                    error => {
                        dispatch({ type: appConstants.LOADED });
                        let _region = [];
                        dispatch({ type: appConstants.GET_REGIONS, _region });
                    }
                );
        }
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
};

function GetCountry(data, Ccode, mobileNo, langID, sessionKey) {
    try {
        return dispatch => {
            dispatch({ type: appConstants.LOADING });
            sportsService.GetCountry(data, Ccode, mobileNo, langID, sessionKey)
                .then(
                    data => {
                        let _countries = data;
                        dispatch({ type: appConstants.LOADED });
                        dispatch({ type: appConstants.GET_COUNTRIES, _countries });
                    },
                    error => {
                        dispatch({ type: appConstants.LOADED });
                        let _countries = [];
                        dispatch({ type: appConstants.GET_COUNTRIES, _countries });
                    }
                );
        }
    } catch (ex) {
        commonClass.writeLog(ex, "GetCountry");
    }
};

function SetSelectedSports(obj) {
    try {
        return dispatch => {
            dispatch({ type: appConstants.SET_SELECTED_SPORTS, obj });
        }
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
};
function SetSelectedRegion(obj) {
    try {
        return dispatch => {
            dispatch({ type: appConstants.SET_SELECTED_REGION, obj });
        }
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
};

function SetSelectedCountry(obj) {
    try {
        return dispatch => {
            dispatch({ type: appConstants.SET_SELECTED_COUNTRIES, obj });
        }
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
};

function SearchSports(data, Ccode, mobileNo, langID, sessionKey) {
    try {
        return dispatch => {
            dispatch({ type: appConstants.LOADING });
            if (data.length < 2) {
                dispatch({ type: appConstants.LOADED });
                let _ssports = null;
                dispatch({ type: appConstants.SEARCH_SPORTS, _ssports });
            }
            else {
                sportsService.SearchSports(data, Ccode, mobileNo, langID, sessionKey)
                    .then(
                        data => {
                            let _ssports = data;
                            dispatch({ type: appConstants.LOADED });
                            dispatch({ type: appConstants.SEARCH_SPORTS, _ssports });
                        },
                        error => {
                            dispatch({ type: appConstants.LOADED });
                            let _ssports = null;
                            dispatch({ type: appConstants.SEARCH_SPORTS, _ssports });
                        }
                    );
            }
        }
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
};
function SearchByDate(data, Ccode, mobileNo, langID, sessionKey) {
    try {
        return dispatch => {
            dispatch({ type: appConstants.LOADING });
            sportsService.SearchByDate(data, Ccode, mobileNo, langID, sessionKey)
                .then(
                    data => {
                        // let _match = data.matches;
                       let _match = data?.matches?.filter(a => a.live_status != "");
                        dispatch({ type: appConstants.LOADED });
                        dispatch({ type: appConstants.SET_MATCH, _match });
                    },
                    error => {
                        dispatch({ type: appConstants.LOADED });
                        let _match = [];
                        dispatch({ type: appConstants.SET_MATCH, _match });
                    }
                );
        }
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
};
function SetSelectedMatch(obj) {
    try {
        return dispatch => {
            dispatch({ type: appConstants.SET_SELECTED_MATCH, obj });
        }
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
};

function GetCalenderMatch(data, Ccode, mobileNo, langID, sessionKey) {
    try {
        return dispatch => {
            dispatch({ type: appConstants.LOADING });
            sportsService.SearchByDate(data, Ccode, mobileNo, langID, sessionKey)
                .then(
                    data => {
                        dispatch({ type: appConstants.LOADED });
                        //for use same store variable by thamil.
                        //let obj = data.matches;
                        //dispatch({ type: appConstants.SET_CALENDER_MATCH, obj });
                        let _match = data.matches;
                        dispatch({ type: appConstants.GET_MATCHES_FOR_TOURNAMENT, _match });
                        //let obj = _match && _match.length > 0 ? _match[0]:{};
                        //dispatch({ type: appConstants.SET_SELECTED_MATCH, obj });
                        //this live game filter
                        _match = data?.matches?.filter(a => a.live_status != "");
                        dispatch({ type: appConstants.SET_MATCH, _match });                    
                    },
                    error => {
                        dispatch({ type: appConstants.LOADED });
                        //for use same store variable by thamil.
                        //let obj = [];
                        //dispatch({ type: appConstants.SET_CALENDER_MATCH, obj });
                        let _match = [];
                        dispatch({ type: appConstants.GET_MATCHES_FOR_TOURNAMENT, _match });
                        //let obj = {};
                        //dispatch({ type: appConstants.SET_SELECTED_MATCH, obj });

                        //this live game filter
                        dispatch({ type: appConstants.SET_MATCH, _match });
                    }
                );
        }
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
};

function GetOddsByMatch(data, Ccode, mobileNo, langID, sessionKey) {
    try {
        return dispatch => {
            //     let obj = _oddlsit.data;
            //     dispatch({ type: appConstants.SET_MATCH_ODDS, obj });
            //     return;
            dispatch({ type: appConstants.LOADING });
            sportsService.GetOddsByMatch(data, Ccode, mobileNo, langID, sessionKey)
                .then(
                    data => {
                        let obj = data;
                        dispatch({ type: appConstants.LOADED });
                        if (obj.available_submarkets && obj.available_submarkets.length > 0) {
                            let mobj = obj.available_submarkets;
                            dispatch({ type: appConstants.AVAIL_SUB_MARKET, mobj });
                        }
                        // if (obj.available_submarkets.length === 0) {
                        //     let sobj = obj.match_statistics;
                        //     dispatch({ type: appConstants.AVAIL_STATISTIC, obj });
                        // }                       
                        dispatch({ type: appConstants.SET_MATCH_ODDS, obj });
                    },
                    error => {
                        dispatch({ type: appConstants.LOADED });
                        let obj = null;
                        dispatch({ type: appConstants.SET_MATCH_ODDS, obj });
                    }
                );
        }
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
};
function SetSelectedTour(obj) {
    try {
        return dispatch => {
            dispatch({ type: appConstants.SET_SELECTED_TOUR, obj });
        }
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
};

function GetMatchesById(matchIds, sportsId, startDate, endDate) {
    try {
        return dispatch => {
            dispatch({ type: appConstants.LOADED })
            sportsService.GetMatchesById(matchIds, sportsId)
                .then(
                    data => {
                        let _matchesteam = data.matches
                        dispatch({ type: appConstants.LOADED })
                        dispatch({ type: appConstants.GET_MATCH_BYID, _matchesteam })
                    },
                    error => {
                        dispatch({ type: appConstants.LOADED })
                        let _matchesteam = []
                        dispatch({ type: appConstants.GET_MATCH_BYID, _matchesteam })
                    }
                )
        }
    } catch (ex) {
        commonClass.writeLog(ex, "GetMatchesById");
    }
}

function GetTeamById(teamId, sportId, startDate, endDate) {
    try {
        return dispatch => {
            dispatch({ type: appConstants.LOADING })
            sportsService.GetTeamById(teamId, sportId, startDate, endDate)
                .then(
                    data => {
                        let _teamlistbyid = data.matches
                        dispatch({ type: appConstants.LOADED })
                        dispatch({ type: appConstants.GET_TEAM_BY_ID, _teamlistbyid })
                    }, error => {
                        dispatch({ type: appConstants.LOADED })
                        let _teamlistbyid = []
                        dispatch({ type: appConstants.GET_TEAM_BY_ID, _teamlistbyid })
                    }
                )
        }

    } catch (error) {
        commonClass.writeLog(error, "GetTeamById");
    }
}

function GetTournaments(data) {
    try {
        return dispatch => {
            dispatch({ type: appConstants.LOADING })
            sportsService.GetTournaments(data)
                .then(data => {
                    let _tournaments = data;
                    dispatch({ type: appConstants.LOADED })
                    dispatch({ type: appConstants.GET_TOURNAMENTS_FOR_COUNTRY_AND_SPORTS, _tournaments })
                }, error => {
                    dispatch({ type: appConstants.LOADED })
                    let _tournaments
                    dispatch({ type: appConstants.GET_TOURNAMENTS_FOR_COUNTRY_AND_SPORTS, _tournaments })
                }
                )
        }
    } catch (error) {
        commonClass.writeLog(error, "GetTournaments");
    }
}
function GetMatchesforTournament(data, Ccode, mobileNo, langID, sessionKey) {
    try {
        return dispatch => {
            dispatch({ type: appConstants.LOADED })
            sportsService.GetMatchesforTournament(data, Ccode, mobileNo, langID, sessionKey)
                .then(
                    data => {
                        let _match = data.matches;
                        dispatch({ type: appConstants.LOADED });
                        dispatch({ type: appConstants.GET_MATCHES_FOR_TOURNAMENT, _match });
                        //this is for live game filter
                        _match = data?.matches?.filter(a => a.live_status != "");
                        // dispatch({ type: appConstants.SET_MATCH, _match });
                    },
                    error => {
                        dispatch({ type: appConstants.LOADED });
                        let _match = [];
                        dispatch({ type: appConstants.GET_MATCHES_FOR_TOURNAMENT, _match });
                        //this is for live game
                        dispatch({ type: appConstants.SET_MATCH, _match });
                    }
                )
        }
    }
    catch (ex) {
        commonClass.writeLog(ex, "GetMatchesforTournament");
    }
}
// get tour by country id
function GetCountryTournaments(data) {
    try {
        return dispatch => {
            dispatch({ type: appConstants.LOADING })
            sportsService.GetCountryTournaments(data)
                .then(data => {
                    let _Countrytournaments = data;
                    dispatch({ type: appConstants.LOADED })
                    dispatch({ type: appConstants.GET_COUNTRY_TOURNAMENTS, _Countrytournaments })
                }, error => {
                    dispatch({ type: appConstants.LOADED })
                    let _Countrytournaments
                    dispatch({ type: appConstants.GET_COUNTRY_TOURNAMENTS, _Countrytournaments })
                }
                )
        }
    } catch (error) {
        commonClass.writeLog(error, "GetCountryTournaments");
    }
}

function GetLivegamesbyid(data, Ccode, mobileNo, langID, sessionKey) {
    // console.log('GetLivegamesbyid-'+Date.now())
    try {
        return dispatch => {
            dispatch({ type: appConstants.LOADING });
            sportsService.GetLivegamesbyid(data, Ccode, mobileNo, langID, sessionKey)
                .then(
                    data => {
                        let _livematch = data.matches;
                        dispatch({ type: appConstants.LOADED });
                        dispatch({ type: appConstants.ALL_LIVE_MATCHES, _livematch });
                    },
                    error => {
                        dispatch({ type: appConstants.LOADED });
                        let _livematch = [];
                        dispatch({ type: appConstants.ALL_LIVE_MATCHES, _livematch });
                    }
                );
        }
    }
    catch (ex) {
        commonClass.writeLog(ex);
    }
};


function GetAvlblOperators(data) {
    try {
        return dispatch => {
            dispatch({ type: appConstants.LOADING })
            sportsService.GetAvlblOperators(data)
                .then(data => {
                    let _aoperators = data;
                    dispatch({ type: appConstants.LOADED })
                    dispatch({ type: appConstants.ALL_OPRATORS, _aoperators })
                }, error => {
                    dispatch({ type: appConstants.LOADED })
                    let _aoperators
                    dispatch({ type: appConstants.ALL_OPRATORS, _aoperators })
                }
                )
        }
    } catch (error) {
        commonClass.writeLog(error, "GetAvlblOperators");
    }
}

// export const cISOcode = (code)=>{
//     try {
//         return dispatch => {
//             let _ciso = code
//             dispatch({type: appConstants.COUNTRY_ISO_CODE , _ciso})
//         }
//     } catch (error) {
//         commonClass.writeLog(error, "ISO code");
//     }
// }


const _oddlsit = {
    "identifier": "match",
    "data": {
        "id": 44957,
        "name": "Gremio - Flamengo",
        "date": "2021-11-24T01:30:00Z",
        "sport_id": 1,
        "tournament_id": 2953,
        "tournament_name": "Campeonato - Serie A",
        "tournament_flag_url": "c/136.png",
        "status": "Upcoming",
        "live_status": "",
        "progress_bar": "",
        "ht_name": "Gremio",
        "ht_id": 21698,
        "ht_flag_url": "t/21698.png",
        "at_name": "Flamengo",
        "at_id": 20265,
        "at_flag_url": "t/20265.png",
        "h2h_home_position": "18",
        "h2h_home_position_change": "up",
        "h2h_away_position": "2",
        "h2h_away_position_change": "up",
        "h2h_home_wins": "",
        "h2h_away_wins": "",
        "h2h_draws": "",
        "h2h_home_wins_rate": "NaN",
        "h2h_away_wins_rate": "NaN",
        "h2h_draws_rate": "NaN",
        "tournament_start_date": "2021-05-29T22:00:00Z",
        "Tournament_end_date": "2021-12-10T00:30:00Z",
        "score": {
            "ht_score": "",
            "at_score": "",
            "score_details": ""
        },
        "match_events": [],
        "match_statistics": [],
        "odds_format_name": "Decimal",
        "odds_format_id": 1,
        "submarket_name": "Full Time Result",
        "submarket_id": 1,
        "odds_name": [
            {
                "name": "1"
            },
            {
                "name": "X"
            },
            {
                "name": "2"
            }
        ],
        "operator_odds": [
            {
                "operator_name": "Unibet (USNJ)",
                "operator_id": 83,
                "operator_flag_url": "o/83_light.png",
                "is_open": true,
                "operator_match_id": "1018235064",
                "operator_tournament_id": "1000094569",
                "odds": [
                    {
                        "odds_value": "1.74",
                        "odds_id": "3023217147",
                        "odds_value_req": "1.74"
                    },
                    {
                        "odds_value": "3.65",
                        "odds_id": "3023217149",
                        "odds_value_req": "3.65"
                    },
                    {
                        "odds_value": "4",
                        "odds_id": "3023217152",
                        "odds_value_req": "4.00"
                    }
                ]
            },
            {
                "operator_name": "SugarHouse",
                "operator_id": 82,
                "operator_flag_url": "o/82_light.png",
                "is_open": true,
                "operator_match_id": "1018235064",
                "operator_tournament_id": "1000094569",
                "odds": [
                    {
                        "odds_value": "1.74",
                        "odds_id": "3023217147",
                        "odds_value_req": "1.74"
                    },
                    {
                        "odds_value": "3.65",
                        "odds_id": "3023217149",
                        "odds_value_req": "3.65"
                    },
                    {
                        "odds_value": "4",
                        "odds_id": "3023217152",
                        "odds_value_req": "4.00"
                    }
                ]
            }
        ],
        "available_submarkets": [
            {
                "submarket_id": 1,
                "operator_count": 4
            },
            {
                "submarket_id": 2,
                "operator_count": 3
            }
        ],
        "tournament_table": [
            {
                "subtournament_table": [
                    {
                        "team_id": 12452,
                        "team_name": "Atletico MG",
                        "team_flag_url": "t/12452.png",
                        "team_position": "1",
                        "team_position_change": "same",
                        "team_points": "71",
                        "subtournament_id": 1,
                        "subtournament_name": "Serie A",
                        "total_fields": {
                            "team_matches_total": "32",
                            "team_win_total": "22",
                            "team_draw_total": "5",
                            "team_loss_total": "5",
                            "team_goals_total": "51:22",
                            "team_goal_diff_total": "+29"
                        }
                    },
                    {
                        "team_id": 20265,
                        "team_name": "Flamengo",
                        "team_flag_url": "t/20265.png",
                        "team_position": "2",
                        "team_position_change": "up",
                        "team_points": "63",
                        "subtournament_id": 1,
                        "subtournament_name": "Serie A",
                        "total_fields": {
                            "team_matches_total": "32",
                            "team_win_total": "19",
                            "team_draw_total": "6",
                            "team_loss_total": "7",
                            "team_goals_total": "62:28",
                            "team_goal_diff_total": "+34"
                        }
                    }
                ]
            }
        ],
        "teams_form": {
            "home_team": {
                "form": []
            },
            "away_team": {
                "form": []
            }
        },
        "match_highlights": [],
        "reversible": false,
        "table_labels": ""
    }
}
function ClearSearchItem() {
    return dispatch => {
        dispatch({ type: appConstants.CLEAR_SPORTITEM });
    }
};
