/*! *****************************************************************************
*T: This file displays the rank of the live match.
*H: PROGRAMMER    : Thamizharasan
*H: DATE-WRITTEN  : 25/07/2024
*H: (C) COPYRIGHT 2024 GI TECH GAMING CO INDIA PRIVATE LIMITED .All rights reserved.

***************************************************************************** */




import { Component } from "react";
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import SandClockProgress from "./SandClockProgress";

class Rank extends Component{
  constructor(props){
    super(props)
  }
  render(){ 
    const {t} = this.props;
    return(
    <>
    {this.props.CurrentMatchOdds?.teams_form?.away_team?.form?.length > 0 && (
    <div className="Rank">
      <div className="rankFilter">
        <div className="rankFilterCont">
            <span className='rankTxt'>Rank</span>
            <SandClockProgress progress={this.props.CurrentMatchOdds?.h2h_home_position || 0} className="custom-sand-clock" />
            <div className='filterMask'><img src="/assets/images/icons/filter.png" alt="filter" /></div>
        </div>
      </div>
      <div className="lastFive">
        <div className="lastFiveCont">
          <div className="lastFive50 lastFiveArrowLft">
          <div className='lastFiveTxt'>{t("lbllastFive")}</div>
              {[...this.props.CurrentMatchOdds.teams_form.home_team.form].reverse().map((x, i) => (
                 <span key={i} className={`lastFiveStatus ${x.row === "L" ? 'lstLoose' : x.row === 'W' ? "lstWin" : 'lstDraw' }`}>{x.row} </span>  
            ))}
          </div>
          <div className="lastFive50 lastFiveArrowRht">
          <div className='lastFiveTxt'>{t("lbllastFive")}</div>
            {[...this.props.CurrentMatchOdds.teams_form.away_team.form].reverse().map((x, i) => (
                <span key={i} className={`lastFiveStatus ${x.row === "L" ? 'lstLoose' : x.row === 'W' ? "lstWin" : 'lstDraw' }`}>{x.row}</span>
            ))}
          </div>
        </div>
      </div>
      <div className="rankFilter">
        <div className="rankFilterCont">
            <span className='rankTxt'>{t("lblRank")}</span>
            <SandClockProgress progress={this.props.CurrentMatchOdds?.h2h_away_position || 0} className="custom-sand-clock" />
            <div className='filterMask'><img src="/assets/images/icons/filter.png" alt="filter" /></div>
        </div>
      </div>
    </div>
)}
</>)}
}
function mapStateToProps(state){
  
    const {CurrentSports, CurrentMatchOdds } = state.sportsReducer;
    return { CurrentSports,  CurrentMatchOdds };
}
const mapActionToProps = {

}
export default connect(mapStateToProps,mapActionToProps) (withTranslation()(Rank)) 