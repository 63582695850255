/*! *****************************************************************************
*T: This file handles calendar date selection functionality.
*H: PROGRAMMER    : Thamizharasan
*H: DATE-WRITTEN  :10/08/2024
*H: (C) COPYRIGHT 2024 GI TECH GAMING CO INDIA PRIVATE LIMITED .All rights reserved.

***************************************************************************** */




// Tabslider.js
import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Tabslider3 = (props) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [slidesToShow, setSlidesToShow] = useState(5);

    const setActiveIndex1 = (element) => {
        setActiveIndex(element.finx);
        props.handleDateChange(element._date);
    }
    useEffect(() => {
        const handleResize = () => {
          if (window.innerWidth < 800) {
            setSlidesToShow(Math.min(_dateArray.length, 3));
          } else {
            setSlidesToShow(Math.min(_dateArray.length, 5));
          }
        };
    
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);
    
      const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        initialSlide: 1,
        centerMode: false,
        centerPadding: '50px',
        prevArrow: <CustomPrevArrow />,
        nextArrow: <CustomNextArrow />,
        afterChange: (current) => {
            const nextElement = _dateArray[current];
            if (nextElement) {
                setActiveIndex1(nextElement); 
            }
        },
    };
    

    let _dateArray = [];
			for (let i = -1; i < 14; i++) {
				var date = new Date();
				date.setDate(date.getDate() + i);
				let dd = date.getFullYear() + '-' + (("0" + (date.getMonth() + 1)).slice(-2)) + "-" + ("0"+date.getDate()).slice(-2);
				let dd1 = date.toDateString();
				// for today and tomorrow
				let showDate = '';
				if (i == -1) {
					showDate = 'Yesterday';
				} else if (i == 0) {
					showDate = 'Today';
				} else if (i == 1) {
					showDate = 'Tomorrow';
				} else {
                    showDate = date.toLocaleDateString('en-US', { weekday: 'long' });
                }
                
                // Get the short month name with date
                let showDate1 = date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
                
                _dateArray.push({ finx: i, showDate, showDate1, valDate: dd, _date: date });
			}

    return (
        <div className="second-slider-container">
            <Slider {...settings}>
                {_dateArray && _dateArray.map((element, inx) =>
                    <div onClick={() => { setActiveIndex1(element) }} className={element.finx === activeIndex ? 'slick-slide active' : 'slick-slide'}>
                        <a><div>{element.showDate}</div><span>{element.showDate1}</span></a>
                    </div>
                )}
            </Slider>
        </div>
    );
};

export default Tabslider3;

// Custom arrow components
const CustomPrevArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <img src="/Assets/images/icons/left-angle2.png" alt="Previous" />
      </div>
    );
  };
  
  const CustomNextArrow = (props) => {
    const { className, onClick } = props;
    return (
      <div className={className} onClick={onClick}>
        <img src="/Assets/images/icons/right-angle2.png" alt="Next" />
      </div>
    );
  };
