/*! *****************************************************************************
*T: From Rank.js match rank related.
*H: PROGRAMMER    : Thamizharasan
*H: DATE-WRITTEN  : 25/07/2024
*H: (C) COPYRIGHT 2024 GI TECH GAMING CO INDIA PRIVATE LIMITED .All rights reserved.

***************************************************************************** */




import React from 'react';

const SandClockProgress = ({ progress, className }) => {
  return (
    <div className={`drinks-cup ${className}`}>
      <div className="drinks-cup-top">
        <div className="liquid" style={{ height: `${progress}%` }}></div>
        <div className="progress-text" style={{ bottom: `${progress}%` }}>
          {`${progress}%`}
        </div>
      </div>
    </div>
  );
};

export default SandClockProgress;