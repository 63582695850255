import { appConstants } from '../helpers/actionType';
import { history } from '../../component/helpers/history';

export const loginActions = {   
    logout,
}

function logout(mobNo, isLoginPage) {
    //userService.logout();
    //history.push('/login');    
    //for reload cart value
    localStorage.removeItem('user');
    localStorage.removeItem('appvariables');
    localStorage.removeItem('wname'); //auto logout ,if url open duplicate window.IdleSesson componet
    localStorage.removeItem('atabId');
    if (isLoginPage != 1) {
        localStorage.setItem('lastloginuser', mobNo);
        history.push('/login');
    }

    return dispatch => {
        dispatch({ type: appConstants.LOGOUT })
    };
};