/*! *****************************************************************************
*T: This file is essential for navigation.
*H: PROGRAMMER    : Thamizharasan
*H: DATE-WRITTEN  : 20/06/2024
*H: (C) COPYRIGHT 2024 GI TECH GAMING CO INDIA PRIVATE LIMITED .All rights reserved.

***************************************************************************** */



import {createBrowserHistory } from 'history';
// export const history = createBrowserHistory({forceRefresh: true, basename:process.env.REACT_APP_BASENAME});
export const history = createBrowserHistory({forceRefresh: true});