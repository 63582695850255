//let user = JSON.parse(localStorage.getItem('user'));
//const g_ccode = (user != null && user.Country_code != "") ? user.Country_code : '0'
export const commonClass = {
    getRequestOptions,
    handleResponse,
    writeLog,
    encrypt,
    decrypt
};

function getRequestOptions(mobileNo, langID = null, sessionKey = null, Ccode) {
    try {
        return {
            method: 'GET',
            //mode: 'cors',
            //crossorigin: true,
            //headers: {
           //     'Content-Type': 'application/json',
                //'Access-Control-Allow-Origin': '*',
           // }
        };
    } catch (ex) {
        console.error("getRequestOptions", ex)
        return null;
    }
};
function handleResponse(response) {
    try {
        return response.text().then(text => {
            if (!response.ok) {
                //if (response.status === 401) {
                //auto logout if 401 response returned from api
                //logout();
                //location.reload(true);
                //}

                //const error = (data && data.message) || response.statusText;
                //const error ={message:( (data && data.message) || response.statusText)};
                const error = { message: response.statusText };
                return Promise.reject(error);
            }
            const data = text && JSON.parse(text);
            // let en_data = Decryption(data);
            return data;
        });
    } catch (ex) {
        // writeLog(ex);
    }
};
function writeLog(ob) {

}

function encrypt(message) {
    try {
        return message;
    }
    catch (ex) {
        return message;
    }

};

function decrypt(ciphertext) {
    try {
        return ciphertext;
    } catch (error) {
        return ciphertext;
    }
};