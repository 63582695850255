/*! *****************************************************************************
*T: This file is essential for defining and exporting Redux action type constants.
*H: PROGRAMMER    : Thamizharasan
*H: DATE-WRITTEN  : 20/06/2024
*H: (C) COPYRIGHT 2024 GI TECH GAMING CO INDIA PRIVATE LIMITED .All rights reserved.

***************************************************************************** */




export const appConstants = {
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_ERROR: 'LOGIN_ERROR',
    LOGOUT: 'LOGOUT',
    REGISTER_REQUEST: 'REGISTER_REQUEST',
    REGISTER_SUCCESS: 'REGISTER_SUCCESS',
    REGISTER_ERROR: 'REGISTER_ERROR',

    SUCCESS: 'ALERT_SUCCESS',
    ERROR: 'ALERT_ERROR',
    CLEAR: 'ALERT_CLEAR',

    LOADING: 'LOADING',
    LOADED: 'LOADED',


    GET_REGIONS:"GET_REGIONS",
    GET_SPORTS:"GET_SPORTS",
    SEARCH_SPORTS:"SEARCH_SPORTS",
    SET_SELECTED_SPORTS:"SET_SELECTED_SPORTS",    
    SET_SELECTED_REGION:"SET_SELECTED_REGION",
    SET_CALENDER_DATE:"SET_CALENDER_DATE",
    SET_MATCH:"SET_MATCH",
    SET_CALENDER_MATCH:"SET_CALENDER_MATCH",
    SET_SELECTED_MATCH:"SET_SELECTED_MATCH",
    SET_MATCH_ODDS:"SET_MATCH_ODDS",
    GET_COUNTRIES:"GET_COUNTRIES",
    SET_SELECTED_COUNTRIES:"SET_SELECTED_COUNTRIES" ,
    GET_MATCHESLIST_BY_ID: "GET_MATCHESLIST_BY_ID",
    SET_SELECTED_TOUR:"SET_SELECTED_TOUR",
    GET_TEAM_BY_ID: "GET_TEAM_BY_ID",
    GET_TOURNAMENTS_FOR_COUNTRY_AND_SPORTS: "GET_TOURNAMENTS_FOR_COUNTRY_AND_SPORTS",
    GET_MATCH_BYID: "GET_MATCH_BYID",
    SET_SELECTED_TOUR:"SET_SELECTED_TOUR",
    GET_MATCHES_FOR_TOURNAMENT:"GET_MATCHES_FOR_TOURNAMENT" ,
    CLEAR_SPORTITEM:"CLEAR_SPORTITEM",
    SET_SELECTED_TOURNAMENT_MATCH:"SET_SELECTED_TOURNAMENT_MATCH",
    GET_COUNTRY_TOURNAMENTS: "GET_COUNTRY_TOURNAMENTS",
    AVAIL_SUB_MARKET:"AVAIL_SUB_MARKET",
    ADD_CURRENT_TOUR: "ADD_CURRENT_TOUR",
    ALL_OPRATORS: "ALL_OPRATORS",
    // COUNTRY_ISO_CODE: "COUNTRY_ISO_CODE"
    ALL_LIVE_MATCHES: "ALL_LIVE_MATCHES"
}
