/*! *****************************************************************************
*T: This file manages and displays sports-related data.
*H: PROGRAMMER    : Thamizharasan
*H: DATE-WRITTEN  : 10/06/2024
*H: (C) COPYRIGHT 2024 GI TECH GAMING CO INDIA PRIVATE LIMITED. All rights reserved.

***************************************************************************** */




import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { sportsActions } from '../../action/SportsAction';
let defaultCountryId = 136;
class Sports extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedSport: null
        };
    }
    componentDidMount() {
        this.props.GetSports(0, 0, 0, 0);
        this.props.SetSelectedTour({name: null})
    }
    static getDerivedStateFromProps(props, state) {
        return {
            CurrentSports: props.CurrentSports,
            SportsList: props.SportsList
        }
    }
    componentDidUpdate(prevProps) {
        //if (this.props.SportsList && this.props.SportsList.sports && !(this.props.CurrentSports && this.props.CurrentSports.id)) {
        if (this.props.SportsList && this.props.SportsList.sports && (this.props.CurrentSports.id != this.state.CurrentSports.id)) {
            this.props.SetSelectedSports(this.props.SportsList.sports[0]);
        }
        if (this.state.selectedSport == null && this.props.CurrentSports && this.props.CurrentSports.id) {
            this.showTournaments(this.props.CurrentSports);
        }
        
    }

    showTournaments = (element) => {
        let data
        if(this.props.CurrentCountries && this.props.CurrentCountries.id){
            data = { country: this.props.CurrentCountries.id, sport: element.id };                        
        }else{
            data = { country: defaultCountryId, sport: element.id };
        }
        this.props.GetTournaments(data);
        if (this.state.selectedSport && this.state.selectedSport.id === element.id) {
            // this.setState({ selectedSport: null });
        } else {
            this.setState({ selectedSport: element });
            this.props.SetSelectedSports(element)
        }
    }
    getTourbyId=(item)=>{
        this.props.SetSelectedTour(item)
        let data = { Tournament: item.id, Matchid: this.state.selectedSport.id, countryCode: this.props.ccountrycode }
        this.props.GetMatchesforTournament(data, 0, 0, 0, 0)
        //this.props.GetLivegamesbytour(data, 0, 0, 0, 0)
    }
    render() {
        const { t } = this.props;
        return (
            <>
                <span className='lbl-2'>{t("lblsports")}</span>
                <div className='quickLink'>
                    <ul>
                        {this.props.SportsList && this.props.SportsList.sports && this.props.SportsList.sports.map((element, index) => (
                            <li key={index} onClick={() => this.showTournaments(element)}>
                                <a className={`bg1 ${this.props.CurrentSports && this.props.CurrentSports.id === element.id ? 'menuActive' : ''}`}>
                                    <div>
                                        <img src={process.env.REACT_APP_CDN_URL + element.flag_url} alt={element.name} />
                                    </div>
                                    <span>{element.name}</span>
                                    {/* <small>{this.props.Tournaments?.tournaments?.length > 0 && <span>{this.props.CurrentSports.id === element.id}</span>}</small> */}
                                </a>
                                {this.state.selectedSport && this.state.selectedSport.id === element.id && this.props.Tournaments?.tournaments?.length > 0 && (
                                    <ul>
                                        {this.props.Tournaments?.tournaments?.map((tournament, index) => (
                                            this.props.CurrentSports.id == tournament.sport_id &&
                                            <li key={index} onClick={() => this.getTourbyId(tournament)}>
                                                <a className={`bg1 ${this.props.CurrentTournament.name == tournament.name ? "menuActive" : ""}`}>
                                                   <div> <img src={process.env.REACT_APP_CDN_URL + tournament.flag_url} alt={tournament.name} /></div>
                                                    <span>{tournament.name}</span>
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
            </>
        )
    }
}
function mapStateToProps(state) {
    const { SportsList, CurrentSports, Tournaments,CurrentTournament, CurrentCountries, ccountrycode } = state.sportsReducer;
    return { SportsList, CurrentSports, Tournaments, CurrentTournament, CurrentCountries, ccountrycode };
}
const mapActionToProps = {
    GetSports: sportsActions.GetSports,
    SetSelectedSports: sportsActions.SetSelectedSports,
    GetTournaments: sportsActions.GetTournaments,
    GetMatchesforTournament:sportsActions.GetMatchesforTournament,
    SetSelectedTour: sportsActions.SetSelectedTour,
    GetLivegamesbytour: sportsActions.GetLivegamesbytour
}
// export default Sports
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Sports))