/*! *****************************************************************************
*T: This file handle and displays rightpannel (about sport and regon)
*H: PROGRAMMER    : Thamizharasan
*H: DATE-WRITTEN  : 20/06/2024
*H: (C) COPYRIGHT 2024 GI TECH GAMING CO INDIA PRIVATE LIMITED .All rights reserved.

***************************************************************************** */




import React, { Component } from 'react';
import { connect } from 'react-redux';
import { alertActions } from '../../action/AlertAction';
import { withTranslation } from 'react-i18next';
import LiveGames from './LiveGames';
import GamesStatics from './GamesStatics';
import MatchEvents from './MatchEvents';
import LeagueTable from './LeagueTable';
class Home extends Component {
    constructor(props) {
        super(props);
    }

    render() {
      const {t} = this.props;
        return (
          <div className='rht'>
            {window.innerWidth > 1080 && <LiveGames /> }
            {window.innerWidth > 1080 && <GamesStatics flagHeading1 = {1}/> }
            {window.innerWidth > 1080 && <MatchEvents flagHeading2 = {1}/> }
            {window.innerWidth > 1080 && <LeagueTable flagHeading3 = {1}/>}
          </div>
        );
    }
}

function mapStateToProps(state) {
}
const mapActionToProps = {
    alertError: alertActions.error,
    alertsucc: alertActions.success,
    clearAlerts: alertActions.clear,
    
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Home))