/*! *****************************************************************************
*T: this file displays current matchEvents
*H: PROGRAMMER    : Thamizharasan
*H: DATE-WRITTEN  : 05/07/2024
*H: (C) COPYRIGHT 2024 GI TECH GAMING CO INDIA PRIVATE LIMITED .All rights reserved.

***************************************************************************** */





import React, { Component, useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { sportsActions } from '../../action/SportsAction';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

class MatchEvents extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  componentDidUpdate() {
  }

  render() {

    var evnt_lst = [];
    if (this.props.CurrentMatchOdds && this.props.CurrentMatchOdds.match_events) {
      let lst = this.props.CurrentMatchOdds.match_events;
      for (var i = 0; i < lst.length; i++) {
        let cObj = lst[i];
        let nObj = null;
        if (cObj.event_name == "substitutionout") {
          i++;
          nObj = lst[i];
        }
        evnt_lst.push(
          {
            event_flag_url: cObj.event_flag_url + (nObj ? "~" + nObj.event_flag_url:""),
            event_name: cObj.event_name + (nObj ? "~" + nObj.event_name:""),
            event_player: cObj.event_player + (nObj ? "~" + nObj.event_player:""),
            event_team: cObj.event_team,
            event_time: parseInt(cObj.event_time)
          }
        );       
      }
    }
    const { t, flagHeading2 } = this.props;
    return (
      <>
        {flagHeading2 == 1 && <h2 className="heading-2">{t("lblMatchEvents")}</h2>}
        <div className="matchEvents">
          <div className="heading-4 bg1">
            <span className='cusdWidth'>Fist Half</span>
            <span className='w-10 fa'>0-0</span>
          </div>
          <div className="matchEventsLi">
            <ul>
              {evnt_lst.sort((a, b) => a.event_time > b.event_time ? 1 : -1).map((ele, inx) =>
                <>{ele.event_team == "1" ?
                  <li key={inx}>
                    <div className="mthEvntsLiLft">
                      <div className="w-30">
                        <div className="mpointsCircle">
                          {ele.event_name.split("~").map((ele_name, inx) =>
                            <span className={`${inx == 0 ? 'mpointsCircleBlue' : 'mpointsCircleRed'}`}></span>
                          )}
                        </div>
                      </div>
                      <div className="mpointsTxt w-40">
                        {ele.event_player.split("~").map((ele_name, inx) =>
                           <span key={inx} className={ele.event_name.split("~")[0] === "substitutionout" ? `${inx === 0 ? 'mENameRed text-right' : 'mENameBlue text-right'}` : ''}>{ele_name}</span>
                        )}
                      </div>
                      <div className="w-20">
                        <div className="mpointsArrow" >
                            <span > 
                          {ele.event_flag_url.split("~").map((ele_flag, inx) =>
                              <img className='w-image-12' src={process.env.REACT_APP_CDN_URL+ele_flag} alt="" />
                          )}
                          </span>
                        </div>
                      </div>
                      <div className="mpoints w-10"><span>{ele.event_time}'</span></div>
                    </div>
                  </li>
                  :
                  <li>
                    <div className="mthEvntsLiRht">
                      <div className="mpoints w-10"><span>{ele.event_time}'</span></div>
                      <div className="w-20">
                        <div className="mpointsArrow">
                            <span className={`${inx == 0 ? 'rDwnArrow' : 'gUpArrow'}`}>
                          {ele.event_flag_url.split("~").map((ele_flag, inx) =>
                              <img className='w-image-12' src={process.env.REACT_APP_CDN_URL+ele_flag} alt="" />
                            )}
                            </span>
                        </div>
                      </div>
                      <div className="mpointsTxt w-40">
                        {ele.event_player.split("~").map((ele_name, inx) =>
                          <span className={ele.event_name.split("~")[0] == "substitutionout" ? `${inx == 0 ? 'mENameRed text-right' : 'mENameBlue text-right'}`: ""}>{ele_name}</span>
                          
                        )}
                      </div>
                      <div className="w-30">
                        <div className="mpointsCircle">
                          {ele.event_name.split("~").map((ele_name, inx) =>
                            <span className={`${inx == 0 ? 'mpointsCircleBlue' : 'mpointsCircleRed'}`}></span>
                          )}
                        </div>
                      </div>
                    </div>
                  </li>
                }  </>
              )}

            </ul>
          </div>

          {/* <div className="heading-4 bg1">
            <span className='cusdWidth'>Half Time</span>
            <span className='w-10'>0-0</span>
          </div> */}
          {/* <div className="matchEventsLi">
            <ul>
              <li>
                <div className="mthEvntsLiRht">
                  <div className="mpoints w-10"><span>88'</span></div>
                  <div className="w-20">
                    <div className="mpointsArrow">
                      <span className='rDwnArrow'></span>
                      <span className='gUpArrow'></span>
                    </div>
                  </div>
                  <div className="mpointsTxt w-40">
                    <span className='mENameRed text-right'>Juan Portillo</span>
                    <span className='mENameBlue text-right'>Juan Portillo</span>
                  </div>
                  <div className="w-30">
                    <div className="mpointsCircle">
                      <span className='mpointsCircleBlue'></span>
                      <span className='mpointsCircleRed'></span>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div> */}
        </div>
      </>
    )
  }
}

function mapStateToProps(state) {
 
  const { allMatchs, CurrentMatch, CurrentSports, CurrentMatchOdds } = state.sportsReducer;
  return { allMatchs, CurrentMatch, CurrentSports, CurrentMatchOdds };
}
const mapActionToProps = {
  SetSelectedMatch: sportsActions.SetSelectedMatch,
}
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(MatchEvents))