/*! *****************************************************************************
*T: This file handles and displays betting odds and match info.
*H: PROGRAMMER    : Thamizharasan
*H: DATE-WRITTEN  : 15/07/2024
*H: (C) COPYRIGHT 2024 GI TECH GAMING CO INDIA PRIVATE LIMITED .All rights reserved.

***************************************************************************** */





import React, { Component, useState, useEffect } from 'react';
import Tabslider2 from '../ToolKit/Tabslider2'
import { sportsActions } from '../../action/SportsAction';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

class Match extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDate: new Date(),
      c_sub_marketid: 1
    }
  }



  componentDidMount() {
    if (!this.props.CurrentMatchOdds) {
      this.selectSubMarket(this.state.c_sub_marketid)
    }
  }
  componentDidUpdate() {
    if (!this.props.CurrentMatchOdds) {
      this.selectSubMarket(this.state.c_sub_marketid)
    }
  }

  selectSubMarket = (id) => {
    this.state.c_sub_marketid = id;
    if (this.props.CurrentMatch) {
      let data = { matchid: this.props.CurrentMatch.id, sub_marketid: id, countrycode: this.props.CurrentCountries.id, oddstype: "1" };
      this.props.GetOddsByMatch(data);
    }
  }

  render() {
    return (
      <>
        {this.props.CurrentMatch ?
          <div className='cent'>
            <h2 className='heading-3'>
              {/* <img className='headingIcon2' src={process.env.REACT_APP_CDN_URL + this.props.CurrentMatch.tournament_flag_url} /> */}
              {this.props.CurrentMatch.tournament_name} ,  {this.props.CurrentMatch.date.replace('T', '  ').replace('Z', '')}
              <span className='liveSign'></span></h2>
            <div className='homeAway'>
              <div className='hAlgsLft'>
                <div className='hAteamLogo'><img src={process.env.REACT_APP_CDN_URL + this.props.CurrentMatch.ht_flag_url} /></div>
                <div className='hAteamName'>{this.props.CurrentMatch.ht_name}</div>
              </div>
              <div className='hAlgsCent'>{this.props.CurrentMatch.score.ht_score} - {this.props.CurrentMatch.score.at_score}
                {this.props.CurrentMatch.has_stream && 1 != 1 && <span><img src="./assets/images/icons/tv.png" /></span>}
              </div>
              <div className='hAlgsRht'>
                <div className='hAteamName'>{this.props.CurrentMatch.at_name}</div>
                <div className='hAteamLogo'><img src={process.env.REACT_APP_CDN_URL + this.props.CurrentMatch.at_flag_url} /></div>
              </div>
            </div>
            {this.props.CurrentMatch.has_stream && 1 != 1 && <div className='gameAnimate'>
              <img src="./assets/images/gameSec.png" />
            </div>}
            {this.props.CurrentMatchOdds && <>
              <Tabslider2 selectSubMarket={this.selectSubMarket} c_sub_marketid={this.state.c_sub_marketid} availableMarkets={this.props.CurrentMatchOdds.available_submarkets} />
              <ul class="matchSheLiCont scrollbar">
                <li>
                  <div class="SchduleHead vdrbg1">
                    {this.props.CurrentMatchOdds.odds_name.map((ele, inx) =>
                      <span className={`HeadTxt ${inx == 1 ? ' forRsltMargin' : ''}`}>{ele.name}</span>
                    )}
                    {/* < span class="HeadTxt">1</span>
                <span class="HeadTxt forRsltMargin">X</span>
                <span class="HeadTxt">2</span> */}
                  </div>
                </li>
                {this.props.CurrentMatchOdds.operator_odds.map((ele, inx) =>
                  <li class="bg3">
                    <div class="imageCont">
                      <span class="imgBet"><img src={process.env.REACT_APP_CDN_URL + ele.operator_flag_url} /></span>
                      <span class="betVal">SEK {ele.operator_id}</span>
                    </div>
                    <div class="result text-center">
                      {ele.odds.map((eodds, inx1) =>
                        <div className={`bettingContnr ${inx1 == 1 ? ' forRsltMargin' : ''}`}>
                          <a att="0" class="ActiveBg">
                            <div class="Betrht">
                              <span>{eodds.odds_value}</span>
                            </div>
                          </a>
                        </div>
                      )}
                    </div>
                  </li>
                )}


              </ul>
            </>}
            {/* My Bet */}
          </div >
          : <div className='cent'>No Match Selected</div>
        }</>
    )
  }
}

function mapStateToProps(state) {

  const { CurrentMatch, CurrentMatchOdds, CurrentRegion, CurrentCountries} = state.sportsReducer;
  return { CurrentMatch, CurrentMatchOdds, CurrentRegion, CurrentCountries };
}
const mapActionToProps = {
  GetOddsByMatch: sportsActions.GetOddsByMatch
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Match))
