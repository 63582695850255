/*! *****************************************************************************
*T: This file handles search functionality and filtering for specific sports.
*H: PROGRAMMER    : Thamizharasan
*H: DATE-WRITTEN  : 17/07/2024
*H: (C) COPYRIGHT 2024 GI TECH GAMING CO INDIA PRIVATE LIMITED .All rights reserved.

***************************************************************************** */




import React, { Component, useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { sportsActions } from '../../action/SportsAction';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searcVal: ""
    }
  }

  // txtSearch = (e) => {
  //   const val = e.target.value;
  //   this.state.searcVal = val;
  //   const regex = /^[a-zA-Z ]*$/;
  //   if (!regex.test(this.state.searcVal) && !this.state.searcVal == '') { return; }
  //   this.props.SearchSports(this.state.searcVal, 0, 0, 0, 0);
  // }

  txtSearch = (e) => {
    const val = e.target.value;
    this.setState({ searcVal: val }, () => {
        const regex = /^[a-zA-Z ]*$/;
        if (!regex.test(this.state.searcVal) && this.state.searcVal !== '') {
            return;
        }
        this.props.SearchSports(this.state.searcVal, 0, 0, 0, 0);
    });
}
  componentDidMount() {
    //  this.props.AllMachesforTeam()
  }
    
  selectSearchItem = (item) => {
    try {
      if (item && item.identifier == "match") {
        let data = {matchIds: item.id, sportId: item.sport_id, countryCode: this.props.ccountrycode}
        this.props.GetMatchesById(data)       
      }
      else if (item && item.identifier == "team") {
        let data = {teamID: item.id, sport: item.sport_id, countryCode: this.props.ccountrycode}
        this.props.GetTeamById(data);      
      }
      else if (item && item.identifier == "tournament") {
        let data = { Tournament: item.id, Matchid: item.sport_id, countryCode: this.props.ccountrycode }
        this.props.GetMatchesforTournament(data, 0, 0, 0, 0)
        //this.props.GetLivegamesbytour(data, 0, 0, 0, 0)
      }
      this.props.ClearSearchItem()
      this.clearText()
    }
    catch (ex) {
      console.log("selectSearchItem", ex)
    }
  }
  clearText = () => {
    this.setState({ searcVal: '' });
  }
  render() {
    const { t } = this.props;
    return (
      <div className='searchSec'>
        <span className='lbl-1'>{t("lblSearch")}</span>
        <input className='search' onChange={(e) => this.txtSearch(e)} type='text' placeholder={t("lblplaceholderserchsportleague")} value={this.state.searcVal} />
        {this.props.SearchSportsList && this.props.SearchSportsList.search_result && this.props.SearchSportsList.search_result && this.props.SearchSportsList.search_result.map((itm, inx) =>
          <div className='searchRslt' onClick={() => this.selectSearchItem(itm)}>{itm.name}
          {/* <button onClick={(e) => { e.stopPropagation(); this.removeRes(itm)}}>x</button> */}
           <button>x</button>
           </div>
        )}
      </div>
    )
  }
}

function mapStateToProps(state) {
  const { SearchSportsList, AllMatchesbyID, CurrentSports, AllTeamsbyID, ccountrycode } = state.sportsReducer;
  return { SearchSportsList, AllMatchesbyID, CurrentSports, AllTeamsbyID, ccountrycode };
}
const mapActionToProps = {
  SearchSports: sportsActions.SearchSports,
  SetSelectedMatch: sportsActions.SetSelectedMatch,
  GetMatchesById: sportsActions.GetMatchesById,
  GetTeamById: sportsActions.GetTeamById,
  GetMatchesforTournament: sportsActions.GetMatchesforTournament,
  ClearSearchItem: sportsActions.ClearSearchItem,
  GetLivegamesbytour: sportsActions.GetLivegamesbytour
}
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Search))
