import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { history } from './helpers/history';
import { alertActions } from './action/AlertAction';
import { loginActions } from './action/loginAction';
import countrycode from './Pages/Home/countrycode.json';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mobileNo: '',
            otp: '',
            password: '',
            Countrydetails: countrycode,
            Selcountry: {},
            showpwd: false,
            TermsAndCond: false,
            user: JSON.parse(localStorage.getItem("ouser"))
        }
        if(this.props.user)
        this.props.logout(this.props.user.mobileNo, 1);
        localStorage.removeItem("ouser")
    }

    handleInputChange(e) {
        const regex = /^[0-9\b]+$/;
        if (regex.test(e.target.value) || e.target.value === '') {
            this.setState({ [e.target.name]: e.target.value });
        }
    }

    toggleCountry = () => {
        window.setCountryToggle();
    }

    countryChange = (data) => {
        this.setState({ Selcountry: data, mobileNo: '' });
        window.setCountryToggle();
    }

    handleInputChangePwd(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

   

    btnLogin(e) {
        e.preventDefault();
        this.setState({TermsAndCond: true});
        
        if (!this.state.mobileNo || this.state.mobileNo.trim() === "") {
            this.showMsg('lblentermobileNo');
            return;
        } else if (this.state.mobileNo.length !== this.state.Selcountry.mobNolength) {
            this.showMsg('lblValidMobileNo');
            return;
        } else if (!this.state.password || this.state.password.trim() === "") {
            this.showMsg('lblEnterPwd');
            return;
        } 
        // const passwordPolicy = /^(?=.*[a-zA-Z])(?=.*\d)[A-Za-z\d@]{5,15}$/;  
        // if (!passwordPolicy.test(this.state.password)) {
        //     this.showMsg('lblInvalidPwd'); 
        //     return;
        // }
        else if(this.state.mobileNo != "0123456789") {
            this.showMsg("lblplzregisterfirst");
            return;
        } else if (this.state.password !== "test@123") {
            this.showMsg("lblIncorrectPassword");
            return;
        }
        
        this.state.password = this.state.password.replace(/'/g, "''");
        
        // this.props.login(
        //     this.state.mobileNo,
        //     this.state.password,
        //     appVariables.LOGIN.Password,
        //     this.props.i18n.language,
        //     this.props.user.loginKey,
        //     this.state.Selcountry.flag
        // );
        
        this.showMsg("lblloginsuccess");
        history.push('/');
        localStorage.setItem('ouser', JSON.stringify({mobile: this.state.mobileNo, password: this.state.password}));
    }
    
    
    static getDerivedStateFromProps(props, state) {
        if (props.user && props.user.isLoggedIn) {
            history.push('/home');    
        }
        return { otp: props.otp };
    }

    componentDidMount() {
        this.setState({ Selcountry: this.state.Countrydetails.default });
        // const user = 
    }

    showMsg = (msg) => {
        this.props.alertError(this.props.t(msg));
    }

    HandleCheckBox = (e) => {
        let Ischecked = e.target.checked;
        let name = e.target.name;
        this.setState({ [name]: Ischecked });
    }
componentDidUpdate(prevProps, prevState) {
    if (this.props.message && (this.props.message !== prevProps.message)) {
        alert(this.props.message);
        this.setState({ TermsAndCond: false });
    } 
    if (this.state.TermsAndCond && this.props.message === prevProps.message) {
        alert(this.props.message);
        this.setState({ TermsAndCond: false });
    }
    
}

    

    render() {
        const { t } = this.props;
        return (
            <>
                <div className='wrap1'>
                    <div className="login-page">
                        <div className="form">
                            <form className="login-form">
                                <div className="head2">
                                    <h3>{t("lbllogin")}</h3>
                                </div>
                                <div>
                                    <span>
                                        <img src="/Assets/images/icons/mobile.png" alt="mobile" />
                                    </span>
                                    <input
                                        className="username"
                                        type="text"
                                        placeholder={t("lblMobileNo")}
                                        name='mobileNo'
                                        value={this.state.mobileNo}
                                        onChange={this.handleInputChange.bind(this)}
                                        maxLength={this.state.Selcountry.mobNolength}
                                        autoComplete="off"
                                        required
                                    />
                                </div>
                                <div>
                                    <span>
                                        <img src="/Assets/images/icons/lock.png" alt="lock" />
                                    </span>
                                    <input
                                        className="password"
                                        type={this.state.showpwd ? "text" : "password"}
                                        name='password'
                                        placeholder={t("lblPassword")}
                                        value={this.state.password}
                                        maxLength={15}
                                        onChange={this.handleInputChangePwd.bind(this)}
                                        autoComplete="off"
                                        required
                                    />
                                </div>
                                <button className="click_btn" onClick={(e) => { this.btnLogin(e) }}>{t('lbllogin')}</button>
                                <p className="message">{t("lblNotregistered")}
                                    <a href={`${process.env.REACT_APP_BASENAME}/register`}>
                                        {t('lblNewSignUpLink')}
                                    </a>
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

function mapStateToProps(state) {
    console.log(state)
    // const { user, otp } = state.loginReducer;
    const { message } = state.alertReducer;
    return {  message};
}

const mapActionToProps = {
    alertError: alertActions.error,
    logout: loginActions.logout,
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Login));
