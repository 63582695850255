/*! *****************************************************************************
*T: This file handles and shows game statistics and data processing.
*H: PROGRAMMER    : Thamizharasan
*H: DATE-WRITTEN  :05/07/2024
*H: (C) COPYRIGHT 2024 GI TECH GAMING CO INDIA PRIVATE LIMITED .All rights reserved.

***************************************************************************** */




import React, { Component, useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { sportsActions } from '../../action/SportsAction';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import ProgressBar from './ProgressBar';
import Rank from './Rank';
import SingleLineProgressBar from "./SingleLineProgressBar"


class GamesStatics extends Component {
    constructor(props) {
        super(props);
    }

     teamData() {
        const data = [
            {"reversible" : false, "id" : 1, "order" : 1, "name" : "Strikes (head)"},
            {"reversible" : false, "id" : 2, "order" : 2, "name" : "Strikes (body)"},
            {"reversible" : false, "id" : 3, "order" : 3, "name" : "Strikes (legs)"},
            {"reversible" : false, "id" : 4, "order" : 4, "name" : "Submissions"},
            {"reversible" : false, "id" : 5, "order" : 5, "name" : "Knockdowns"},
            {"reversible" : false, "id" : 6, "order" : 6, "name" : "Takedowns"},
            {"reversible" : false, "id" : 7, "order" : 1, "name" : "Shots (total)"},
            {"reversible" : false, "id" : 8, "order" : 2, "name" : "Shots (on goal)"},
            {"reversible" : true, "id" : 9, "order" : 3, "name" : "Fouls"},
            {"reversible" : false, "id" : 10, "order" : 4, "name" : "Corners"},
            {"reversible" : true, "id" : 11, "order" : 5, "name" : "Offsides"},
            {"reversible" : false, "id" : 12, "order" : 6, "name" : "Possession"},
            {"reversible" : true, "id" : 13, "order" : 7, "name" : "Yellow cards"},
            {"reversible" : true, "id" : 14, "order" : 8, "name" : "Red cards"},
            {"reversible" : false, "id" : 15, "order" : 9, "name" : "Saves"},
            {"reversible" : false, "id" : 16, "order" : 10, "name" : "Passes (total)"},
            {"reversible" : false, "id" : 17, "order" : 11, "name" : "Passes (accurate)"},
            {"reversible" : false, "id" : 18, "order" : 1, "name" : "Q1"},
            {"reversible" : false, "id" : 19, "order" : 2, "name" : "Q2"},
            {"reversible" : false, "id" : 20, "order" : 3, "name" : "Q3"},
            {"reversible" : false, "id" : 21, "order" : 4, "name" : "Q4"},
            {"reversible" : false, "id" : 22, "order" : 5, "name" : "OT"},
            {"reversible" : false, "id" : 23, "order" : 1, "name" : "Round 1"},
            {"reversible" : false, "id" : 24, "order" : 2, "name" : "Round 2"},
            {"reversible" : false, "id" : 25, "order" : 3, "name" : "Round 3"},
            {"reversible" : false, "id" : 26, "order" : 4, "name" : "Round 4"},
            {"reversible" : false, "id" : 27, "order" : 5, "name" : "Round 5"},
            {"reversible" : false, "id" : 28, "order" : 1, "name" : "In1"},
            {"reversible" : false, "id" : 29, "order" : 2, "name" : "In2"},
            {"reversible" : false, "id" : 30, "order" : 3, "name" : "In3"},
            {"reversible" : false, "id" : 31, "order" : 4, "name" : "In4"},
            {"reversible" : false, "id" : 32, "order" : 5, "name" : "In5"},
            {"reversible" : false, "id" : 33, "order" : 6, "name" : "In6"},
            {"reversible" : false, "id" : 34, "order" : 7, "name" : "In7"},
            {"reversible" : false, "id" : 35, "order" : 8, "name" : "In8"},
            {"reversible" : false, "id" : 36, "order" : 9, "name" : "In9"},
            {"reversible" : false, "id" : 37, "order" : 10, "name" : "Extra"},
            {"reversible" : false, "id" : 38, "order" : 11, "name" : "T1"},
            {"reversible" : false, "id" : 39, "order" : 12, "name" : "T2"},
            {"reversible" : false, "id" : 40, "order" : 1, "name" : "P1"},
            {"reversible" : false, "id" : 41, "order" : 2, "name" : "P2"},
            {"reversible" : false, "id" : 42, "order" : 3, "name" : "P3"},
            {"reversible" : false, "id" : 43, "order" : 6, "name" : "Pen."},
            {"reversible" : false, "id" : 44, "order" : 1, "name" : "Aces"},
            {"reversible" : true, "id" : 45, "order" : 2, "name" : "Double Faults"},
            {"reversible" : false, "id" : 46, "order" : 3, "name" : "1st Serve Percentage"},
            {"reversible" : false, "id" : 47, "order" : 4, "name" : "1st Serve Points Won"},
            {"reversible" : false, "id" : 48, "order" : 5, "name" : "2nd Serve Points Won"},
            {"reversible" : false, "id" : 49, "order" : 6, "name" : "Break Points Saved"},
            {"reversible" : false, "id" : 50, "order" : 7, "name" : "1st Return Points Won"},
            {"reversible" : false, "id" : 51, "order" : 8, "name" : "2nd Return Points Won"},
            {"reversible" : false, "id" : 52, "order" : 9, "name" : "Break Points Converted"},
            {"reversible" : false, "id" : 53, "order" : 10, "name" : "Max Points In Row"},
            {"reversible" : false, "id" : 54, "order" : 11, "name" : "Service Points Won"},
            {"reversible" : false, "id" : 55, "order" : 12, "name" : "Return Points Won"},
            {"reversible" : false, "id" : 56, "order" : 13, "name" : "Total Points Won"},
            {"reversible" : false, "id" : 57, "order" : 14, "name" : "Max Games In Row"},
            {"reversible" : false, "id" : 58, "order" : 15, "name" : "Service Games Won"},
            {"reversible" : false, "id" : 59, "order" : 16, "name" : "Return Games Won"},
            {"reversible" : false, "id" : 60, "order" : 17, "name" : "Total Games Won"},
            {"reversible" : false, "id" : 61, "order" : 18, "name" : "Country"},
            {"reversible" : false, "id" : 62, "order" : 19, "name" : "Serving now"}
            
        ];
      
        return data;
      }
      
    componentDidMount() {
    }

    componentDidUpdate() {
    }
    percentageCal = (c_m_odds) => {
        if (!c_m_odds?.tournament_start_date || !c_m_odds?.Tournament_end_date) {
          return 0;
        }
      
        const currentDate = new Date(c_m_odds?.date);
        const startDate = new Date(c_m_odds?.tournament_start_date);
        const endDate = new Date(c_m_odds?.Tournament_end_date);
        if (startDate > endDate) {
          return 100;
        }
        if (currentDate < startDate) {
          return 0;
        }
        const totalDuration = (endDate - startDate) / (1000 * 3600 * 24);
        const differenceInDays = (currentDate - startDate) / (1000 * 3600 * 24);
        const endPercentage = (differenceInDays / totalDuration) * 100;
      
        return Math.round(endPercentage);
      }

    render() {
        const { t, flagHeading1 } = this.props;
        const remainingPercentage = this.percentageCal(this.props.CurrentMatchOdds);
        const TeamStats = this.teamData()
        return (
            <div className="statics">
           {flagHeading1 == 1 && <h2 class="heading-2">{t("lblGamesStatics")}</h2>}
         <div className='stabarSec'>
          {!isNaN(this.props.CurrentMatchOdds?.h2h_home_wins_rate) && !isNaN(this.props.CurrentMatchOdds?.h2h_away_wins_rate) && (
          <ProgressBar progress1={Math.round(this.props.CurrentMatchOdds?.h2h_home_wins_rate)} progress2={Math.round(this.props.CurrentMatchOdds?.h2h_draws_rate)} progress3={Math.round(this.props.CurrentMatchOdds?.h2h_away_wins_rate)} />)
          }
         </div>
      <Rank />
      <SingleLineProgressBar progress={remainingPercentage} className="custom-progress-bar" />
                <div className='teamStats bg1'>
                {this.props.CurrentMatchOdds?.match_statistics?.length > 0 ? (
                    <div>
                        <div className="staticsLft">
                            <img src={process.env.REACT_APP_CDN_URL+this.props.CurrentMatchOdds?.ht_flag_url}alt="Team 1 Logo"/>
                        </div>
                        <div className="staticsCent" style={{ padding: "7px 0 0 0" }}> Team Stats </div>
                        <div className="staticsRht">
                        <img src={process.env.REACT_APP_CDN_URL+this.props.CurrentMatchOdds?.at_flag_url} alt="Team 2 Logo"/>
                        </div>
                    </div> )
                    :
                    <div>Team Stats</div>
                    
                }

             </div>
                    {
                    this.props.CurrentMatchOdds && this.props.CurrentMatchOdds.match_statistics && this.props.CurrentMatchOdds.match_statistics.length > 0 &&
                    <ul>
                        {this.props.CurrentMatchOdds.match_statistics.map((stat, i) => (
                                <li key={i}>
                                    <div className="staticsLft">{(stat.ht_statistic?stat.ht_statistic:"-")}</div>
                                        <div className="staticsCent">{TeamStats.filter(ts => ts.id == stat.statistic_id)[0].name}</div> 
                                        <div className="staticsRht">{(stat.at_statistic?stat.at_statistic:"-")}</div>
                                </li>
                        ))}
                    </ul>
                }

            </div>
        )
    }
}

function mapStateToProps(state) {
    const { allMatchs, CurrentMatch, CurrentSports, CurrentMatchOdds } = state.sportsReducer;
    return {  allMatchs, CurrentMatch, CurrentSports, CurrentMatchOdds };
}
const mapActionToProps = {
    SetSelectedMatch: sportsActions.SetSelectedMatch,
}
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(GamesStatics))