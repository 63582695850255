/*! *****************************************************************************
*T: This file configures and exports the Redux store
*H: PROGRAMMER    : Thamizharasan
*H: DATE-WRITTEN  : 20/06/2024
*H: (C) COPYRIGHT 2024 GI TECH GAMING CO INDIA PRIVATE LIMITED .All rights reserved.

***************************************************************************** */



import { createStore, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from '../reducer/rootReducer';
export const store = createStore(
    rootReducer,
    applyMiddleware(
        thunkMiddleware
    )
);