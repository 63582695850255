/*! *****************************************************************************
*T: This file handles fetching data from all APIs.
*H: PROGRAMMER    : Thamizharasan
*H: DATE-WRITTEN  : 15/06/2024
*H: (C) COPYRIGHT 2024 GI TECH GAMING CO INDIA PRIVATE LIMITED .All rights reserved.

***************************************************************************** */




import { commonClass } from "../helpers/commonClass"
const originalFetch = require('isomorphic-fetch');
const fetch = require('fetch-retry')(originalFetch);
export const sportsService = {
	GetRegions,
	GetSports,
	SearchSports,
	SearchByDate,
	GetOddsByMatch,
	GetCountry,
	GetMatchesById,
	GetTeamById,
	GetTournaments,
	GetMatchesforTournament,
	GetCountryTournaments,
	GetLivegamesbyid,
	GetAvlblOperators
};
const _apiUrl = process.env.REACT_APP_API_URL;

async function getFetch(_url, Ccode, mobileNo, langID, loginKey, retrycnt = 0) {
	try {
		//_url="?"+_url;	
		let _option = await commonClass.getRequestOptions(mobileNo, langID, loginKey, Ccode);
		return fetch(`${_apiUrl}${_url}`, {
			..._option,
			retryOn: function (attempt, error, response) {
				// retry on any network error, or 4xx or 5xx status codes
				// if (error !== null || response.status == 403) {
				if (response && response.status == 401) {
					console.log(`retrying, attempt number ${attempt + 1}`);
					//return attempt >= 2 ? false : true;         
					if (retrycnt < 1) {
						retrycnt++;
						getFetch(_url, mobileNo, langID, loginKey, retrycnt)
					}
					return false
				}
			}
		})
			.then(commonClass.handleResponse)
			.then(user => { return user; });
	}
	catch (ex) {
		commonClass.writeLog(ex, "getFetch=>" + _url);
	}
}
function GetRegions(data, Ccode, mobileNo, langID, sessionKey) {
	try {
		return getFetch('continents?sport=' + data, Ccode, mobileNo, langID, sessionKey)
			.then(results => {
				if (results != null && results.data != null) { results = results.data; }
				return results;
			});
	}
	catch (ex) {
		commonClass.writeLog(ex, "GetRegion");
	}
}

function GetCountry(data, Ccode, mobileNo, langID, sessionKey) {
    try {
		// return getFetch('countries?continent=' + continentId + '&sport=' + sportId, Ccode, mobileNo, langID, sessionKey)
        return getFetch(`countries?continent=${data.continent}&sport=${data.sport}`)
            .then(results => {
                if (results != null && results.data != null) {
                    return results.data;
                }
                return [];
            });
    } catch (ex) {
        commonClass.writeLog(ex, "GetCountry");
    }
}

function GetSports(Ccode, mobileNo, langID, sessionKey) {
	try {
		return getFetch('sports', Ccode, mobileNo, langID, sessionKey)
			.then(results => {
				if (results != null && results.data != null) { results = results.data; }
				return results;
			});
	}
	catch (ex) {
		commonClass.writeLog(ex, "GetSports");
	}
}
function SearchSports(data, mobileNo, langID, sessionKey) {
	try {
		return getFetch('search?query=' + data, mobileNo, langID, sessionKey)
			.then(results => {
				if (results != null && results.data != null) { results = results.data; }
				return results;
			});
	}
	catch (ex) {
		commonClass.writeLog(ex, "SearchSports");
	}
}
function SearchByDate(data, mobileNo, langID, sessionKey) {
	try {
		return getFetch('matches?date=' + data.sdate + '&sport=' + data.sport + '&countrycode=' + 'SE')
			.then(results => {
				if (results != null && results.data != null) { results = results.data; }
				return results;
			});
	}
	catch (ex) {
		commonClass.writeLog(ex, "SearchByDate");
	}
}
function GetOddsByMatch(data, mobileNo, langID, sessionKey) {
	try {
		return getFetch('match?match=' + data.matchid + '&submarket=' + data.sub_marketid+ '&countrycode=' + 'SE' + '&oddstype=' + data.oddstype)
			.then(results => {
				if (results != null && results.data != null) { results = results.data; }
				return results;
			});
	}
	catch (ex) {
		commonClass.writeLog(ex, "GetOddsByMatch");
	}
}

function GetMatchesById(data, startDate, endDate){
	try {
		// return getFetch(`matches?matches=${matchIds}&sport=${sportId}`)
		let url = `matches?matches=${data.matchIds}&sport=${data.sportId}&countrycode=${'SE'}` 
		if (startDate && endDate) {
            url += `&startdate=${startDate}&enddate=${endDate}`;
        }
		return getFetch(url)
		.then(results =>{
			if(results !=null && results.data !=null){
				return results.data;
			}
			return [];
		})
	} catch (ex) {
		commonClass.writeLog(ex, "GetMachesforTeam");
	}
}

function GetTeamById(data, startDate, endDate){
	try {
		return getFetch(`matches?team=${data.teamID}&sport=${data.sport}&countrycode=${'SE'}`)
		.then(results =>{
			if(results !=null && results.data !=null){
				return results.data;
			}
			return []
		})
	} catch (ex) {
		commonClass.writeLog(ex, "GetMachesTeamById" )
	}
}


function GetTournaments(data){
try {
	return getFetch(`tournaments?country=${data.country}&sport=${data.sport}`)
	.then(results =>{
		if(results !=null && results.data !=null){
			return results.data;
		}
		return []
	})
} catch (error) {
	commonClass.writeLog(error, "GetTournaments" )
}
}

function 	GetMatchesforTournament(data) {
	try {
		return getFetch('matches?tournament=' + data.Tournament + '&sport=' + data.Matchid + '&countrycode=' + 'SE')
			.then(results => {
				if (results != null && results.data != null) { results = results.data; }
				return results;
			});
	}
	catch (ex) {
		commonClass.writeLog(ex, "GetMatchesforTournament")
	}
}

function GetCountryTournaments(data){
	try {
		return getFetch(`tournaments?country=${data.country}&sport=${data.sport}`)
		.then(results =>{
			if(results !=null && results.data !=null){
				return results.data;
			}
			return []
		})
	} catch (error) {
		commonClass.writeLog(error, "GetCountryTournaments" )
	}
	}

	function GetLivegamesbyid(data) {
		try {
			return getFetch(`matches?matches=${data.matchIds}&sport=${data.sportId}&countrycode=${'SE'}`)
				.then(results => {
					if (results != null && results.data != null) { results = results.data; }
					return results;
				});
		}
		catch (ex) {
			commonClass.writeLog(ex, "get live games by id")
		}
	}

function GetAvlblOperators(data){
	try {
		return getFetch(`operators?country=${'SE'}`)
		.then(results =>{
			if(results !=null && results.data !=null){
				return results.data;
			}
			return []
		})
	} catch (error) {
		commonClass.writeLog(error, "GetAvlblOperators" )
	}
	}
