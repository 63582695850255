/*! *****************************************************************************
*T: This file manages and displays live games.
*H: PROGRAMMER    : Thamizharasan
*H: DATE-WRITTEN  : 28/08/2024
*H: (C) COPYRIGHT 2024 GI TECH GAMING CO INDIA PRIVATE LIMITED .All rights reserved.

***************************************************************************** */





import React, { Component, useState, useEffect } from 'react';
import { Link } from 'react-router-dom'
import { sportsActions } from '../../action/SportsAction';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
class LiveGames extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFetchlivegm: 0,
        }
        let tmr1 = localStorage.getItem("ltimer");
        this.liveTimer = tmr1 ? parseInt(tmr1) : 30000
    }

    componentDidUpdate(prevProps, prevState) {
        // if (this.props.CurrentSports !== prevProps.CurrentSports && this.state.allMatchs?.length > 0 ||this.props.ccountrycode !== prevProps.ccountrycode) {
        //     this.startlivegamesinterval();
        // }      

        if (this.state.allMatchs?.length == 0 && this.state.liveMaches?.length == 0) {
            clearInterval(this.livegamesinterval);
            this.state.isFetchlivegm = this.state.allMatchs?.length;
        }
        // else if (this.props.CurrentSports !== prevProps.CurrentSports || (this.state.allMatchs?.length > 0 && (this.state.liveMaches?.length == 0 || this.state.isFetchlivegm != this.state.liveMaches?.length))) {
        else if (this.state.CurrentSports !== prevProps.CurrentSports || this.state.isFetchlivegm != this.state.allMatchs?.length) {
            this.state.isFetchlivegm = this.state.allMatchs?.length;
            // let dt = this.state.allMatchs;
            // let matchIds = dt?.map(el => el.id);
            // let data = { matchIds: [matchIds], sportId: this.props.CurrentSports.id, countryCode: this.props.ccountrycode }
            // this.props.GetLivegamesbyid(data)
            this.startlivegamesinterval();
        }

    }
    componentDidMount() {
        // if(this.props.CurrentSports.id){
        //     this.handleDateChange();
        //     this.setState({isFetchlivegm: 1})
        // }
    }
    static getDerivedStateFromProps(props, state) {
        const newState = {
          allMatchs: props.allMatchs,
          liveMaches: props.liveMaches,
          TournamentMatches: props.TournamentMatches,
          CurrentSports: props.CurrentSports
        };
        if (props.allMatchs?.length == 0) {
          newState.liveMaches = 0;
        }      
        return newState;
      }

    handleDateChange = () => {
        let date = new Date();
        let dd = date.getFullYear() + '-' + (("0" + (date.getMonth() + 1)).slice(-2)) + "-" + ("0" + date.getDate()).slice(-2);
        let data = { sdate: dd, sport: this.props.CurrentSports.id, countryCode: this.props.ccountrycode };
        this.props.SearchByDate(data, 0, 0, 0, 0);
        // this.startlivegamesinterval()
    };
    // refresh every 30 sec to get updated live matches score 
    startlivegamesinterval = () => {
        clearInterval(this.livegamesinterval);
        try {
            if (this.props.CurrentSports && this.props.CurrentSports.id && this.state.allMatchs?.length > 0) {
                let dt = this.state.allMatchs;
                let matchIds = dt.map(el => el.id);
                if (matchIds && matchIds?.length > 0) {
                    let data = { matchIds: [matchIds], sportId: this.props.CurrentSports.id, countryCode: this.props.ccountrycode };
                    this.props.GetLivegamesbyid(data)
                    this.livegamesinterval = setInterval(() => this.props.GetLivegamesbyid(data),this.liveTimer);// this.liveTimer
                   
                
                }
            }
        } catch (error) {
            console.error('Error in startlivegamesinterval:', error);
        }
    }

    componentWillUnmount() {
        clearInterval(this.livegamesinterval);
    }

    render() {
        const { t } = this.props;
    
        let matchGroup = {};
        //let data = this.state.allMatchs;
        let data = this.state.liveMaches;
        for (let a = 0; a < data?.length; a++) {
            let match = data[a];
            if (match.live_status !== "") {
                let TournamentName = match.tournament_name;
                if (!matchGroup[TournamentName]) {
                    matchGroup[TournamentName] = [];
                }
                matchGroup[TournamentName].push(match);
            }
        }
        let matchData = Object.entries(matchGroup);

        return (
            <>
                <Link to="#" className='sportsMenuBtn'>{t("lblFiltersportsorArea")}<img src="/assets/images/icons/right-angle2.png" /></Link>
                <h2 className='heading-2'>{t("lbllivegames")}<span className='liveSign'></span></h2>
                <div className='liveGameSec'>
                    <ul>
                        {matchData?.map(([TournamentName, matches], tournamentIndex) => (
                            <>
                                {matches?.map((element, inx) => (
                                    <>
                                        {inx === 0 && <div className=' teamNmeTime2 bg2'><img className='headingIcon3' src={process.env.REACT_APP_CDN_URL + element.at_flag_url} /> {element.date?.split('T')[1]?.split(':')?.slice(0, 2)?.join(':')} &nbsp;&nbsp;&nbsp; {TournamentName}</div>}
                                        <li onClick={() => { this.props.SetSelectedMatch(element) }}>
                                            <Link to="#">
                                                <div className='lgsLft'>
                                                    <div className='gameIcon'><span className="liveSign"></span></div>
                                                    <div className='teamLogo'><img src={process.env.REACT_APP_CDN_URL + element.ht_flag_url} /></div>
                                                    <div className='teamName'>{element.ht_name}</div>
                                                </div>
                                                <div className='lgsCent'>{element.score.ht_score} - {element.score.at_score}
                                                    {/* {element.has_stream && <span><img src="./assets/images/icons/tv.png" alt="stream icon" /></span>} */}
                                                    <small>{element.live_status}</small>
                                                </div>
                                                <div className='lgsRht'>
                                                    <div className='teamName'>{element.at_name}</div>
                                                    <div className='teamLogo'><img src={process.env.REACT_APP_CDN_URL + element.at_flag_url} alt='' /></div>
                                                </div>
                                            </Link>
                                        </li>
                                    </>
                                ))}
                            </>
                        ))}
                        {/* {matchData.length == 0 && <div>{t("lblLoading")}</div>}  */}
                    </ul>



                    {/* <li>
                    <Link to ="#">
                        <div className='lgsLft'>
                            <div className='gameIcon'><img src="./assets/images/sports/football-w.png" alt=''/></div>
                            <div className='teamLogo'><img src="./assets/images/team-logo/team-1.png" alt=''/></div>
                            <div className='teamName'>Townsom</div>
                        </div>
                        <div className='lgsCent'>0 - 1</div>
                        <div className='lgsRht'>
                            <div className='teamName'>Boston Celtic</div>
                            <div className='teamLogo'><img src="./assets/images/team-logo/team-2.png" alt=''/></div>
                        </div>
                    </Link>
                  </li> */}

                </div>
            </>
        )
    }
}

function mapStateToProps(state) {
    console.log(state)
   
    const { loading } = state.alertReducer;
    const { CurrentSports, allMatchs, CurrentMatch, Loading, CurrentTournament, CurrentMatchOdds, ccountrycode, liveMaches, TournamentMatches } = state.sportsReducer;
    return {  allMatchs, CurrentMatch, CurrentSports, loading, CurrentTournament, CurrentMatchOdds, ccountrycode, liveMaches, TournamentMatches };
}
const mapActionToProps = {
    SetSelectedMatch: sportsActions.SetSelectedMatch,
    SearchByDate: sportsActions.SearchByDate,
    GetLivegamesbyid: sportsActions.GetLivegamesbyid,
}
export default connect(mapStateToProps, mapActionToProps)(withTranslation()(LiveGames))