/*! *****************************************************************************
*T: This file handles the header section and its content rendering.
*H: PROGRAMMER    : Thamizharasan
*H: DATE-WRITTEN  : 10/06/2024
*H: (C) COPYRIGHT 2024 GI TECH GAMING CO INDIA PRIVATE LIMITED .All rights reserved.

***************************************************************************** */



import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { history } from '../helpers/history'

function Header() {
    const [icon, setIcon] = useState(false)
    const closeSideDrawer = () =>{setIcon(false)}
    const { t } = useTranslation();
    const user = JSON.parse(localStorage.getItem("ouser"));
    

    useEffect(() => {
       window.menu()   
      }, []);
     
      const getCookie = (name) => {
        try {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            let lang_val = process.env.REACT_APP_DEFAULTLANGUAGE;
            //if (parts.length === 2)
            if (parts.length >= 2) {
                //lang_val =parts.pop().split(';').shift();
                let lst = value.split(`;`);
                let fobj = lst.filter(a => a.indexOf('olang=') >= 0);
                if (fobj && fobj.length > 0) {
                    let fobj1 = fobj[fobj.length-1].split(`=`);
                    lang_val = fobj1[1];
                }
            }
            console.log("selected lang : " + lang_val);
            return lang_val;
        }
        catch (ex) {
            return process.env.REACT_APP_DEFAULTLANGUAGE;
        }
    };
    
    const { i18n } = useTranslation();
    const [selectedLang, setSelectedLang] = useState(getCookie("olang"));
   

      const changeLanguage = (event) =>{
        i18n.changeLanguage(event)
        document.cookie = "olang=" + event;
         setSelectedLang(event);
        //  window.location.reload();
      }

      const getFAQURL = (lang) =>{
        switch(lang) {
            case "sv":
                return "https://web.oddsium.com/sv/faq";
            case "es":
            case "pt":
                return "https://web.oddsium.com/es/faq";
            case "el":
                return "https://web.oddsium.com/el/faq";
            default:
                return "https://web.oddsium.com/faq"; 
        }
    }
    const handleLoginClick = () => {
        if(user){
        localStorage.removeItem("ouser")
        history.push("/login")
        } 

    };
    return (
           
        <header className='bg1'>
    <div className='wrap1'>
        <div className='logo'>
            <Link to ='/' className='nav-logo' onClick={closeSideDrawer}><img src="./assets/images/logo.png" alt="Logo" /></Link>
        </div>
        <span className="menuOpen">&#9776;</span>
        <div className='headRht mobileMenu'>
            <div className='menuCont'>
                <a href="#" className="menuClose">&times;</a>
                <ul className={icon ? 'nav-menu active' : 'nav-menu'}>
                <li style={{ listStyle: 'none', display: 'flex', alignItems: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {/* <a href="https://oddsium.com" target="_blank" rel="noopener noreferrer" style={{ display: 'inline-flex', alignItems: 'center', textDecoration: 'none' }} onClick={closeSideDrawer}>
                        <img src="./assets/images/icons/about.png" alt="Logo" style={{ marginRight: '8px' }} /> {t("lblaboutus")}
                        </a> */}
                    </div>
                </li>

                       {/* <li><Link to ='/responsible-gaming' className='nav-links' onClick={closeSideDrawer}><img src="./assets/images/icons/resgame.png" alt="Logo" />{t("lblresponsiblegame")}</Link></li>  */}
                       <li>
                            {/* <a href={getFAQURL(selectedLang)} target="_blank" rel="noopener noreferrer" className='nav-links' onClick={closeSideDrawer}>
                                <img src="./assets/images/icons/help.png" alt="Logo" />{t("lblhelp")}
                            </a> */}
                        </li>         
                </ul>
                <div className="selLanguage">
                    <a className="selLangBtn" href="#">
                    <img src={`/assets/images/icons/${selectedLang === 'en' ? 'English' : selectedLang === 'el' ? 'Greek' : selectedLang === "sv" ? 'Swedish' : selectedLang === "es" ? 'Spanish' : selectedLang === "pt" ? 'Portugese' : 'English'}.png`} />
                         <span className="selectedLanguageName" style={{ textTransform: 'uppercase' }} onClick={()=>changeLanguage("en")}>{selectedLang}</span>
                    </a>
                    <ul className="selLangCont bg1">
                    <li><Link to="#" onClick={() => changeLanguage("en")}><img src="/assets/images/icons/English.png" />English</Link></li>
                            <li><Link to="#" onClick={() => changeLanguage("sv")}><img src="/assets/images/icons/Swedish.png" />Svenska</Link></li>
                            <li><Link to="#" onClick={() => changeLanguage("el")}><img src="/assets/images/icons/Greek.png" />Ελληνικά</Link></li>
                            <li><Link to="#" onClick={() => changeLanguage("es")}><img src="/assets/images/icons/Spanish.png" />Española</Link></li>
                            <li><Link to="#" onClick={() => changeLanguage("pt")}><img src="/assets/images/icons/Portugese.png" />Português</Link></li>
                    </ul>
                </div>
                {/* {user && <button className='loginBtn' onClick={handleLoginClick}>logout</button>} */}
            </div>
        </div>
    </div>
</header> 
    )
  }
  
  export default Header