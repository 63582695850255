/*! *****************************************************************************
*T: This file handles the error alert message process.
*H: PROGRAMMER    : Thamizharasan
*H: DATE-WRITTEN  : 15/06/2024
*H: (C) COPYRIGHT 2024 GI TECH GAMING CO INDIA PRIVATE LIMITED .All rights reserved.

***************************************************************************** */




import { appConstants } from '../helpers/actionType';
import { history } from '../helpers/history';
export const alertActions = {
    clear,
    loading,
    success,
    error
};

function clear(flag) {
    return dispatch => {
        localStorage.removeItem('alertmsg');
        dispatch({ type: appConstants.CLEAR });
        if (flag == 1) {
            history.push('/login');
        }     
    };
}

function loading(message) {
    return { type: appConstants.LOADING, message };
}

function success(message) {
    return { type: appConstants.SUCCESS, message };
}

function error(message,loginflag) {
    if (loginflag == undefined) {
        loginflag = 0;
    }
    return { type: appConstants.ERROR, message };
}
