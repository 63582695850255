/*! *****************************************************************************
*T: This file manages the home page and its components.
*H: PROGRAMMER    : Thamizharasan
*H: DATE-WRITTEN  :10/06/2024
*H: (C) COPYRIGHT 2024 GI TECH GAMING CO INDIA PRIVATE LIMITED .All rights reserved.

***************************************************************************** */




import React, { Component } from 'react';
import { connect } from 'react-redux';
import { alertActions } from '../../action/AlertAction';
import { withTranslation } from 'react-i18next';
import Match from './Match';
import MatchList from './MatchList';
import RightPanel from './RightPanel';
import LeftPanel from './LeftPanel';
import Homeslider from '../ToolKit/Homeslider'
import { history } from '../../helpers/history';
class Home extends Component {
    constructor(props) {
        super(props);
    }
componentDidMount(){
    localStorage.setItem('ouser', JSON.stringify({mobile: "0123456789", password: "test@123"}));
    const user = JSON.parse(localStorage.getItem("ouser"));
    //    if (!user ){
    //     this.props.history.push("/login")
    //    }  
}
    render() {
        const {t} = this.props
        return (
            <div className='wrap1'>
                <div className='lft'>
                    {/* <Homeslider /> */}
                    {/* <h2 className='heading-1'>{t("lblfindlatestsports")}</h2> */}
                    <div className='lftCont'>
                     <a href="#" className="menuClose">&times;</a>
                     <div className='lftFlterSec'>
                     <LeftPanel />
                     </div>
                     
                    </div>
                    {/* <Match />  */}
                    <MatchList />
                </div>
                <RightPanel />
            </div>
        );
    }
}


function mapStateToProps(state) {
    const { CurrentMatch } = state.sportsReducer;
    // const { user, appvariables } = state.loginReducer;
    const { serverTime } = state.commonReducer;
    return {  CurrentMatch };
}
const mapActionToProps = {
    alertError: alertActions.error,
    alertsucc: alertActions.success,
    // getBanner: loginActions.getBanner
}

export default connect(mapStateToProps, mapActionToProps)(withTranslation()(Home))