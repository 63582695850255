/*! *****************************************************************************
*T: This file handle alertMessege
*H: PROGRAMMER    : Thamizharasan
*H: DATE-WRITTEN  : 15/06/2024
*H: (C) COPYRIGHT 2024 GI TECH GAMING CO INDIA PRIVATE LIMITED .All rights reserved.

***************************************************************************** */




import { appConstants } from '../helpers/actionType';

const initialState = {
    loading: false,
    appvariables: {}, // Initialize appvariables
    message: ""
};

export function alertReducer(state = initialState, action) {
    switch (action.type) {
        case appConstants.CLEAR:
            return {
                ...state,
                message: ""
            };
        case appConstants.LOADING:
            return {
                ...state,
                loading: true
            };
        case appConstants.LOADED:
            return {
                ...state,
                loading: false
            };
        case appConstants.SUCCESS:
            return {
                ...state,
                type: 'alert-success',
                alertcolor: "#28a745",
                // alertcolor: "#a05f26",
                // alertcolor: "#3BC789",
                alertTitle: "Info",
                message: action.message
            };
        case appConstants.ERROR:
            return {
                ...state,
                type: 'alert-danger',
                alertcolor: "#a05f26",
                alertTitle: "Error",
                loginFlag: action.loginFlag,
                message: action.message
            };
        default:
            return state;
    }
}
