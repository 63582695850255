import { appConstants } from "../helpers/actionType";

let sTime = localStorage.getItem('sTime');
try {
  sTime = sTime ? parseInt(sTime) : 0
}
catch (ex) {
  sTime = 0;
}
const initialState = { serverTime: sTime };
export const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case appConstants.GET_SERVERTIME_SUCCESS:
      return {
        ...state,
        serverTime: action.serverTime
      };
    case appConstants.GET_SERVERTIME_ERROR:
      localStorage.setItem('sTime', -1);
      return {
        ...state,
        serverTime: -1
      };

    default:
      return { ...state }
  }

}