/*! *****************************************************************************
*T: This file handles the routing and site control section
*H: PROGRAMMER    : Thamizharasan
*H: DATE-WRITTEN  : 10/06/2024
*H: (C) COPYRIGHT 2024 GI TECH GAMING CO INDIA PRIVATE LIMITED .All rights reserved.

***************************************************************************** */


import React, { Component } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './component/Pages/Home/Home';
import Header from './component/Header/Header';
import Footer from './component/Footer/Footer';
import { history } from './component/helpers/history';
import { PrivateRoute } from './component/helpers/PrivateRoute';
import { commonClass } from './component/helpers/commonClass';
import ErrorBoundary from './component/Error'
import PageNotFound from './component/Pages/404'
import { withTranslation } from 'react-i18next';
import "./css/common.css"
import "./css/home.css"
import Login from './component/Login';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locadet: window.location.pathname.split('/')[window.location.pathname.split('/').length - 1].toLowerCase()
    }
    let _myapp = JSON.parse(localStorage.getItem('appvariables'));
    if (_myapp && process.env.REACT_APP_Version != _myapp.version) {
      localStorage.setItem('appvariables', JSON.stringify({ countryCode: "", currency: process.env.REACT_APP_CURRENCY, version: process.env.REACT_APP_Version, bVersion: _myapp.bVersion }));//bVersion: _myapp.bVersion
      window.location.href = window.location.href;
      window.location.replace(window.location.href);
    }
    if (_myapp == null || _myapp == undefined) {
      localStorage.setItem('appvariables', JSON.stringify({ countryCode: process.env.REACT_APP_Countrycode, currency: process.env.REACT_APP_CURRENCY, version: process.env.REACT_APP_Version, bVersion: "0~0~0~0" })); //bVersion: "0~0~0~0"
    }
    let _user = localStorage.getItem('user');
    if (_user == null || _user == undefined) {
      let userdata = { userid: "0", username: "", mobileNo: "0", balance: "0", winbalance: "0", loginKey: "0", emailID: "", loginExpiry: 0, Imageinfo: " ", Created_date: "", Player_name: "", Country_code: process.env.REACT_APP_Countrycode };
      localStorage.setItem('user', commonClass.encrypt(JSON.stringify(userdata)));

    }
    // window.loadscriptLacy();

  }

  componentDidMount() {
    // if (this.props.location) {
    //   const query = new URLSearchParams(this.props.location.search);
    //   const _timer = query && query.get(' ')
    //   const _livetimer = query && query.get('livetimer')
    //   if (_timer && _timer != "") {
    //     localStorage.setItem("ntimer", (parseInt(_timer) * 1000));
    //   }
    //   if (_livetimer && _livetimer != "") {
    //     localStorage.setItem("ltimer", (parseInt(_livetimer) * 1000));
    //   }
    // }

     if (window.location.search) {
      const query = new URLSearchParams(window.location.search);
      const _timer = query && query.get('timer')      
      if (_timer && _timer != "") {
        localStorage.setItem("ntimer", (parseInt(_timer) * 1000));
      }
      const _livetimer = query && query.get('livetimer')
      if (_livetimer && _livetimer != "") {
        localStorage.setItem("ltimer", (parseInt(_livetimer) * 1000));
      }
    }
  }

  getQueryVariable = (variable) => {
    var query = window.location.search.substring(1);
    console.log(query)//"app=article&act=news_content&aid=160990"
    var vars = query.split("&");
    console.log(vars) //[ 'app=article', 'act=news_content', 'aid=160990' ]
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      console.log(pair)//[ 'app', 'article' ][ 'act', 'news_content' ][ 'aid', '160990' ] 
      if (pair[0] == variable) { return pair[1]; }
    }
    return (false);
  }

  render() {
    return (
      <>

        <ErrorBoundary>
          <Router basename={process.env.REACT_APP_BASENAME} history={history}>
            {/* <Common /> */}
            <Header />
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path='/home' component={Home} />
              <Route exact path='/login' component={Login} />
              <Route exact path="/*" component={PageNotFound} />
            </Switch>
            <Footer />
          </Router>
        </ErrorBoundary>
      </>
    );
  }
}
//export default App;
export default withTranslation()(App)