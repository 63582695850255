/*! *****************************************************************************
*T: This file combines and exports the root reducer for Redux.
*H: PROGRAMMER    : Thamizharasan
*H: DATE-WRITTEN  : 15/06/2024
*H: (C) COPYRIGHT 2024 GI TECH GAMING CO INDIA PRIVATE LIMITED .All rights reserved.

***************************************************************************** */




import { combineReducers } from 'redux';
import { commonReducer } from './commonReducer';
import { alertReducer } from './alertReducer';
import { sportsReducer } from './sportsReducer';
const rootReducer = combineReducers({
  commonReducer,
  alertReducer,
  sportsReducer
});

export default rootReducer;