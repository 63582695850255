/*! *****************************************************************************
*T: this file displays the match progress bar of game statics
*H: PROGRAMMER    : Thamizharasan
*H: DATE-WRITTEN  : 25/07/2024
*H: (C) COPYRIGHT 2024 GI TECH GAMING CO INDIA PRIVATE LIMITED .All rights reserved.

***************************************************************************** */




import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

class ProgressBar extends Component {
  constructor(props){
    super(props)
  }
  render() {
    const { progress1, progress2, progress3 } = this.props;
  return (
      <div className="progress-bar-container">
        <div className="progress-bar progress1" style={{ width: `${progress1}%` }}>
          <span className='staCount'>1</span>
          {/* <span className="progress-label">{`${this.props.CurrentMatchOdds?.h2h_home_wins}-${progress1}`}%</span> */}
          <span className="progress-label">{progress1}%</span>
        </div>
        <div className="progress-bar progress3" style={{ width: `${progress3}%` }}>
          <span className='staCount'>x</span>
          <span className="balance-label">{progress3}%</span>
        </div>
        <div className="progress-bar progress2" style={{ width: `${progress2}%` }}>
        <span className='staCount'>2</span>
          <span className="balance-label">{progress2}%</span>
        </div>
    </div>
    );
  }
}

function mapStateToProps(state){
 
  const {CurrentMatchOdds} = state.sportsReducer;
  return { CurrentMatchOdds}
}
const mapActionToProps = {

}
export default connect(mapStateToProps, mapActionToProps) (withTranslation()(ProgressBar)) ;
