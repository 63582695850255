/*! *****************************************************************************
*T: This file handles incorrect route URLs and displays. 'Under Construction' 
*H: PROGRAMMER    : Thamizharasan
*H: DATE-WRITTEN  : 10/08/2024
*H: (C) COPYRIGHT 2024 GI TECH GAMING CO INDIA PRIVATE LIMITED .All rights reserved.

***************************************************************************** */




import React from 'react';
import { withTranslation } from 'react-i18next';
import Header from '../Header/Header';
import { connect } from 'react-redux';

class PageNotFound extends React.Component {
    constructor(props) {
        super(props);        
    }
    
    render() {
        const { t } = this.props;
            return (<>

                <div class="wrap2" style={{fontSize:'30px',textAlign:'center'}}>
				<div class="flex flex-col items-center mt-20">
					{/* <div class="text-slate-600">4041 {t('lblNotfound')}</div>
					<div class="text-2xl font-semibold">{t('lblRegret')}</div>
					<p class="text-xl text-center text-slate-700">
						{t('lbl404Msg')}
					</p> */}
					<p class="text-xl text-center text-slate-700">
					This page is under construction
					</p>					
					<button class="bg-brand px-4 py-1 text-white rounded-md">
						<a href={`${process.env.REACT_APP_BASENAME}/Home`} style={{backgroundColor:'#000'}}>{t('lblGohome')}</a>
					</button>
				</div>
			</div></>
            );
        }
}

function mapStateToProps(state) {
    
}
const mapActionToProps = {
    
}


export default connect(mapStateToProps, mapActionToProps)(withTranslation()(PageNotFound))
